import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Quota from './Quota';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';

import {
  QUOTA_ADD_CRITERION,
  ADD_QUOTA,
  QUOTA_SAVE_ID,
  QUOTA_SAVE_CRITERION_VALUE,
  QUOTA_SAVE_QUOTA,
  DELETE_QUOTA,
  propTypes as statePropTypes
} from './SurveyStateManager';

const propTypes = {
  availableCriteria: statePropTypes.availableCriteria,
  quotas: statePropTypes.quotas,
  dispatch: PropTypes.func
};
const defaultProps = { quotas: [] };

const MainContainer = styled.div``;

const StyledDivider = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 8px;
  height: 2px;
`;

const QuotasContainer = ({ availableCriteria, quotas, dispatch }) => {
  const addQuota = () => {
    dispatch({
      type: ADD_QUOTA
    });
  };

  const addCriterion = (reactKey, criterionKey) => {
    dispatch({
      type: QUOTA_ADD_CRITERION,
      payload: {
        reactKey,
        criterion: availableCriteria.find(
          criterion => criterion.key === criterionKey
        )
      }
    });
  };

  const changeId = (reactKey, value) => {
    dispatch({
      type: QUOTA_SAVE_ID,
      payload: {
        reactKey,
        value
      }
    });
  };

  const changeCriterionValue = (reactKey, criterionKey, value) => {
    dispatch({
      type: QUOTA_SAVE_CRITERION_VALUE,
      payload: {
        reactKey,
        criterionKey,
        value
      }
    });
  };

  const changeQuota = (reactKey, value) => {
    dispatch({
      type: QUOTA_SAVE_QUOTA,
      payload: {
        reactKey,
        value
      }
    });
  };

  const deleteQuota = reactKey => {
    dispatch({
      type: DELETE_QUOTA,
      payload: {
        reactKey
      }
    });
  };

  return (
    <MainContainer>
      {quotas.map(quotum => {
        return (
          <React.Fragment key={quotum.reactKey}>
            <Quota
              availableCriteria={availableCriteria}
              addCriterion={addCriterion}
              changeId={changeId}
              changeCriterionValue={changeCriterionValue}
              changeQuota={changeQuota}
              deleteQuota={deleteQuota}
              values={quotum}
            />
            <StyledDivider />
          </React.Fragment>
        );
      })}
      <IconButton aria-label="add" color="primary" onClick={addQuota}>
        <AddIcon />
      </IconButton>
    </MainContainer>
  );
};
QuotasContainer.propTypes = propTypes;
QuotasContainer.defaultProps = defaultProps;
export default QuotasContainer;
