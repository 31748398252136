import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import FilterIcon from '@material-ui/icons/CalendarToday';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import WarnIcon from '@material-ui/icons/Warning';
import { useAuth0 } from '../../../auth/react-auth0-spa';
import Table from '../../Table';
import Papa from 'papaparse';

import { SelectedMonth } from '../../Table/MonthYearFilters';
import FilterDialog from './FilterDialog';

import { runPanelStats, fetchPanelStats } from '../../../models/paneldata';

const StyledButton = styled(Button)`
  margin-left: 16px;
`;

const DataButton = styled(Button)`
  margin-left: 16px;
  margin-top: 32px;
`;
const ToolBlock = styled.div`
  display: flex;
`;
const MonthContainer = styled.div`
  display: flex;
`;

let twoMonthsAgo = new Date();
twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

const checkDataBusy = panelData => {
  if (!panelData) {
    return false;
  }
  const now = new Date();
  return panelData.some(panel => {
    const updated = panel.updatedAt;
    if (!(updated instanceof Date && !isNaN(updated))) {
      return false;
    }
    const minutesAgo = Math.ceil((now - updated) / (1000 * 60));
    return (
      (panel.status === 'fetching' || panel.status === 'pending') &&
      minutesAgo < 20
    );
  });
};

const checkDataAllowRefresh = panelData => {
  if (!panelData || panelData.length === 0) {
    return true;
  }
  const now = new Date();
  return panelData.some(panel => {
    if (panel.status === 'error') {
      return true;
    }
    const updated = panel.updatedAt;
    if (!(updated instanceof Date && !isNaN(updated))) {
      return false;
    }
    const minutesAgo = Math.ceil((now - updated) / (1000 * 60));
    if (
      (panel.status === 'fetching' || panel.status === 'pending') &&
      minutesAgo > 20
    ) {
      return true;
    }
    if (minutesAgo > 12 * 60) {
      return true;
    }
    return false;
  });
};

const PanelMonthlyCount = () => {
  const isMounted = useRef(true);
  const [panelStats, setPanelStats] = useState([]);
  const [pollingTimer, setpollingTimer] = useState(false);
  const [period, setPeriod] = useState({
    year: twoMonthsAgo.getFullYear(),
    month: twoMonthsAgo.getMonth()
  });
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const { getTokenSilently } = useAuth0();

  // set isMounted to false when we unmount the component
  useEffect(() => {
    return () => {
      if (pollingTimer) {
        clearInterval(pollingTimer);
      }
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPanels = useCallback(
    async (polling = false) => {
      const token = await getTokenSilently();
      fetchPanelStats(period.year, period.month, token)
        .then(fetchedData => {
          if (isMounted.current) {
            setPanelStats(fetchedData);
            if (
              (polling && (!fetchedData || fetchedData.length === 0)) ||
              checkDataBusy(fetchedData)
            ) {
              setpollingTimer(
                setTimeout(() => {
                  if (isMounted.current) {
                    getPanels(polling);
                  }
                }, 3000)
              );
            } else {
              setpollingTimer(null);
              setWaiting(false);
            }
          }
        })
        .catch(err => {
          console.warn(err);
        });
      // .finally(() => setWaiting(false));
    },
    [getTokenSilently, period.year, period.month]
  );

  const waitForUpdatedPanelData = token => {
    fetchPanelStats(period.year, period.month, token)
      .then(fetchedData => {
        if (isMounted.current) {
          if (fetchedData && fetchedData.length && checkDataBusy(fetchedData)) {
            setPanelStats(fetchedData);
            setpollingTimer(
              setTimeout(() => {
                if (isMounted.current) {
                  getPanels(true);
                }
              }, 1000)
            );
          } else {
            setpollingTimer(
              setTimeout(() => {
                if (isMounted.current) {
                  waitForUpdatedPanelData(token);
                }
              }, 1000)
            );
          }
        }
      })
      .catch(err => {
        console.warn(err);
      });
    // .finally(() => setWaiting(false));
  };

  const doRunPanelStats = async () => {
    if (period.year && period.month) {
      setWaiting(true);
      const token = await getTokenSilently();
      runPanelStats(period.year, period.month, token)
        .then(() => {
          waitForUpdatedPanelData(token);
        })
        .catch(err => {
          console.warn(err);
        });
    }
  };

  useEffect(() => {
    getPanels();
  }, [getPanels]);

  const tableData = panelStats.map(panel => {
    let status = '';
    let data = { panelId: panel.panelId, panelName: panel.panelName };
    switch (panel.status) {
      case 'fetching':
      case 'pending':
        status = <CircularProgress size={16} />;
        break;
      case 'error':
        status = <ErrorIcon />;
        break;
      case 'ready':
        status = <CheckIcon />;
        data = panel;
        break;

      default:
        status = panel.status;
        data = panel;
        break;
    }
    return { ...data, status };
  });

  const getDownloadData = () =>
    panelStats.map(row => {
      const {
        panelId,
        panelName,
        updatedAt,
        projectCount,
        completed,
        dropout,
        nonresponders,
        overquota,
        screenout,
        totalInvites
      } = row;
      return {
        panelId,
        panelName,
        updatedAt,
        projectCount,
        completed,
        dropout,
        nonresponders,
        overquota,
        screenout,
        totalInvites
      };
    });

  const downloadTable = () => {
    const csv = Papa.unparse(getDownloadData(), { delimiter: ';' });
    const dataStr = `data:text/csv;charset=utf-8,${csv}`;
    var tempLink = document.createElement('a');
    tempLink.href = dataStr;
    const fileName = `panelaantallen_${period.year}-${period.month + 1}.csv`;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
  };

  const columns = [
    {
      label: 'id',
      data: 'panelId',
      rowHeader: true
    },
    {
      label: 'name',
      data: 'panelName'
    },
    {
      label: 'status',
      data: 'status'
    },
    {
      label: 'updated',
      data: d =>
        d.updatedAt
          ? d.updatedAt.toLocaleString('en-GB', {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          : ''
    },
    {
      label: 'projectCount',
      align: 'right',
      data: 'projectCount'
    },
    {
      label: 'totalInvites',
      align: 'right',
      data: 'totalInvites'
    },
    {
      label: 'completed',
      align: 'right',
      data: 'completed'
    },
    {
      label: 'dropout',
      align: 'right',
      data: 'dropout'
    },
    {
      label: 'screenout',
      align: 'right',
      data: 'screenout'
    },
    {
      label: 'overquota',
      align: 'right',
      data: 'overquota'
    },
    {
      label: 'nonresponders',
      align: 'right',
      data: 'nonresponders'
    }
  ];
  return (
    <div>
      <ToolBlock>
        <MonthContainer>
          <SelectedMonth value={period} />
          <StyledButton
            variant="contained"
            color="secondary"
            startIcon={<FilterIcon />}
            onClick={() => {
              setFilterDialogOpen(true);
            }}
          >
            Change month
          </StyledButton>
          <FilterDialog
            value={period}
            onSubmit={newValue => {
              setPeriod(newValue);
              setFilterDialogOpen(false);
            }}
            open={filterDialogOpen}
            onCancel={() => setFilterDialogOpen(false)}
          />
        </MonthContainer>
        <StyledButton
          onClick={downloadTable}
          variant="contained"
          color="secondary"
          disabled={
            waiting ||
            !tableData ||
            !tableData.length ||
            !tableData.some(
              panel =>
                panel.completed &&
                (typeof panel.completed === 'number' ||
                  typeof panel.completed === 'string')
            )
          }
        >
          Download
        </StyledButton>
      </ToolBlock>

      <Table data={tableData} columns={columns} rowKey="panelId" />

      {(waiting || pollingTimer) && (!panelStats || !panelStats.length) ? (
        <CircularProgress />
      ) : null}

      {waiting || pollingTimer ? null : (
        <DataButton
          variant="contained"
          color={panelStats && panelStats.length ? 'default' : 'secondary'}
          onClick={doRunPanelStats}
          disabled={waiting || !checkDataAllowRefresh(panelStats)}
          endIcon={<WarnIcon />}
        >
          {panelStats && panelStats.length ? 'Recalculate data' : 'Get data'}
        </DataButton>
      )}
      <DataButton
        variant="contained"
        color="default"
        onClick={() => getPanels()}
        // disabled={waiting}
      >
        Refresh
      </DataButton>
    </div>
  );
};

export default PanelMonthlyCount;
