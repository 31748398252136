import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MainContainer = styled.div`
  display: inline-block;
`;

function mod(n, m) {
  return ((n % m) + m) % m;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const MonthFilters = ({ id, value, onChange }) => {
  const [monthOptions, setMonthOptions] = useState([]);
  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const options = [];
    for (let index = 0; index < 10; index++) {
      const month = mod(currentMonth - index, 12);
      const year = currentYear + Math.floor((currentMonth - index) / 12);
      options.push({
        month: month + 1,
        year
      });
    }
    setMonthOptions(options);
    // preselect previous month
    onChange(options[1]);
  }, [onChange]);

  return (
    <MainContainer>
      <Autocomplete
        disableClearable
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        id={id || 'select-box'}
        options={monthOptions}
        getOptionLabel={option =>
          option ? `${months[option.month - 1]} ${option.year}` : ''
        }
        style={{ width: 300 }}
        renderInput={params => (
          <TextField {...params} label="month" variant="outlined" fullWidth />
        )}
      />
    </MainContainer>
  );
};
export default MonthFilters;
