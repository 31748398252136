import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { surveyProps } from './types';

const propTypes = {
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      surveyId: surveyProps.surveyId,
      title: surveyProps.title,
      inDatabase: PropTypes.bool
    })
  ),
  /**
   * function onSelectSurvey(surveyData)
   *    surveyData: surveyProps
   */
  onSelectSurvey: PropTypes.func.isRequired
};
const defaultProps = {
  surveys: []
};

const SurveyList = ({ surveys, onSelectSurvey }) => {
  return (
    <TableContainer>
      <Table size="small" aria-label="list of surveys">
        <TableHead>
          <TableRow>
            <TableCell>Survey id</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surveys.map(survey => (
            <TableRow
              key={survey.surveyId}
              onClick={() => onSelectSurvey(survey)}
              hover
            >
              <TableCell component="th" scope="row">
                {survey.surveyId}
              </TableCell>
              <TableCell>{survey.title}</TableCell>
              <TableCell>{survey.status}</TableCell>
              <TableCell>
                {survey.inDatabase ? null : <ReportProblemOutlinedIcon />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
SurveyList.propTypes = propTypes;
SurveyList.defaultProps = defaultProps;
export default SurveyList;
