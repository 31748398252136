import React from 'react';
import styled from 'styled-components';

const JsonContainer = styled.pre`
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const PreviewJson = ({ json }) => {
  return (
    <div>
      <JsonContainer>{json}</JsonContainer>
    </div>
  );
};

export default PreviewJson;
