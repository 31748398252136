import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import VideoStreamMerger from 'video-stream-merger';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AudioSpectrum from 'react-audio-spectrum';
import MainLayout from '../../layout/MainLayout';
import { useAuth0 } from '../../../auth/react-auth0-spa';
import { convertVideo } from '../../../models/moment-to-moment';

import M2M from './M2M';

const colors = {
  dvjRed: '#FC0D1C',
  dvjBlue: '#2161F7',
  grey: '#7F7F7F', //50%
  lightGrey: '#bfbfbf', //25%
  lighterGrey: '#e6e6e6' //10%
};

const settings = {
  advertisement: {
    width: 500
  },
  spacing: 40,
  chart: {
    width: 500,
    height: 500
  },
  audioSpectrum: {
    width: 450,
    height: 300,
    meterWidth: 2,
    meterGap: 4
  }
};

settings.audioSpectrum.meterCount = Math.floor(
  (1.7 * settings.audioSpectrum.width) /
    (settings.audioSpectrum.meterWidth + settings.audioSpectrum.meterGap)
);

settings.outputVideo = {
  width: settings.advertisement.width + settings.spacing + settings.chart.width,
  height: settings.chart.height
};

const Container = styled.div``;

const UserInputForm = styled(Paper)`
  padding: 32px;
  margin: 32px 2%;
`;
const InputContainer = styled.div`
  margin: 36px;
`;
const StyleTextField = styled(TextField)`
  width: 300px;
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${settings.advertisement.width}px;
`;

const StyledVideo = styled.video`
  width: ${settings.advertisement.width}px;
  ${({ hidden }) =>
    hidden &&
    `
    display: none;
  `}
`;

const AudioSpectrumContainer = styled.div`
  padding: 0
    ${Math.floor(
      (settings.advertisement.width - settings.audioSpectrum.width) / 2
    )}px;
`;

// For previewing:
// const StyledOutputVideo = styled.video`
//   width: ${settings.outputVideo.width}px;
//   height: ${settings.outputVideo.height}px;
// `;

const InputStreamContainer = styled.div`
  display: flex;
  background: #ffffff;
  width: ${settings.outputVideo.width}px;
  height: ${settings.outputVideo.height}px;
  margin: 32px 2%;
`;

const RUN_STATUS_NONE = false;
const RUN_STATUS_COMPLETE = 0;
const RUN_STATUS_RECORDING = 1;
const RUN_STATUS_CONVERTING = 2;

const Moment2Moment = () => {
  let advertisementRef = useRef();
  // let outputRef = useRef();
  let chartRef = useRef();
  let visualizerRef = useRef();
  const { getTokenSilently } = useAuth0();

  /**
   * @type {[('moment-to-moment'|'engagement'), Function]} graphType
   */
  const [graphType, setGraphType] = useState('moment-to-moment');
  const [yAxisRange, setYAxisRange] = useState([0, 100]);
  const [m2mInput, setM2mInput] = useState('');
  const [graphData, setGraphData] = useState({
    m2mValues: [],
    percentWatch: 0,
    triggerRecord: false
  });
  const [adUrlInput, setAdUrlInput] = useState('');
  const [adSrc, setAdSrc] = useState();
  const [running, setRunning] = useState(RUN_STATUS_NONE);
  const [adReady, setAdReady] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  /**
   * @type {[(''|'local'|'external'), Function]} adLoaded
   */
  const [adLoaded, setAdLoaded] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const ticker = useRef(null);

  useEffect(() => {
    return () => {
      // onUnmount: clear ticker (timer)
      if (ticker.current) {
        clearInterval(ticker.current);
      }
    };
  }, []);

  function checkDataPoints(values) {
    const advertisement = advertisementRef.current;
    if (advertisement) {
      const checkInputAndVidLength = values.length - advertisement.duration;
      if (checkInputAndVidLength > 2) {
        setFieldErrors(prev => ({
          ...prev,
          m2mInput: 'Too many data inputs'
        }));
        return false;
      } else if (checkInputAndVidLength < -2) {
        setFieldErrors(prev => ({
          ...prev,
          m2mInput: 'Too few data inputs'
        }));
        return false;
      }
    }

    setFieldErrors(prev => ({
      ...prev,
      m2mInput: ''
    }));
    return true;
  }

  const getPercentWatch = () => {
    const advertisement = advertisementRef.current;
    const newPercentWatch =
      advertisement && advertisement.currentTime && advertisement.duration
        ? (advertisement.currentTime / advertisement.duration) * 100
        : 0;
    return newPercentWatch;
  };

  /**
   * Parses user input for data values. Sets parsed values in state (which will draw the chart)
   *
   * @param {Boolean} triggerRecord - if true: recording of video stream will start on next render
   */
  function drawChart(triggerRecord) {
    let values = m2mInput.split('%').map(val => val.trim());
    values = values.filter(val => val.length > 0).map(val => parseInt(val, 10));
    if (values.some(val => isNaN(val))) {
      setFieldErrors(prev => ({
        ...prev,
        m2mInput: 'Please make sure no words are entered, just numbers'
      }));
      return false;
    } else {
      const dataPointsOk = checkDataPoints(values);
      const newState = { m2mValues: values, percentWatch: 0 };
      if (typeof triggerRecord !== 'undefined') {
        newState.triggerRecord = triggerRecord;
      }
      if (!dataPointsOk) {
        newState.triggerRecord = false;
      }
      setGraphData(prev => ({ ...prev, ...newState }));
      return dataPointsOk;
    }
  }

  const addChartStream = useCallback(merger => {
    if (chartRef.current) {
      chartRef.current.chartInstance.update();
      const canvasElm = chartRef.current.chartInstance.canvas;
      let canvasStream = canvasElm.captureStream();

      const x = settings.outputVideo.width - settings.chart.width;
      const y = (settings.outputVideo.height - settings.chart.height) / 2;

      merger.addStream(canvasStream, {
        x: settings.outputVideo.width - settings.chart.width,
        y: (settings.outputVideo.height - settings.chart.height) / 2,
        width: settings.chart.width,
        height: settings.chart.height,
        mute: true,

        draw: (ctx, frame, done) => {
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, merger.width, merger.height);
          ctx.drawImage(
            frame,
            x,
            y,
            settings.chart.width,
            settings.chart.height
          );
          done();
        }
      });
    }
  }, []);

  const addAdvertisementStream = useCallback(
    merger => {
      if (advertisementRef.current) {
        const videoEl = advertisementRef.current;
        const vidRatio = videoEl.videoWidth / videoEl.videoHeight;
        const vidW = settings.advertisement.width;
        const vidH = vidW / vidRatio;
        try {
          const advertisementStream = videoEl.captureStream();
          merger.addStream(advertisementStream, {
            x: 0,
            y: (settings.outputVideo.height - vidH) / 2,
            width: vidW,
            height: vidH,
            mute: false
          });
          return true;
        } catch (error) {
          let errMessage = `Cannot capture video.`;
          if (adLoaded === 'external') {
            errMessage = `${errMessage} Clear the field and try uploading the advertisement from your computer or choose another source`;
          }
          setFieldErrors(prev => ({
            ...prev,
            advertisement: errMessage
          }));
          return false;
        }
      }
    },
    [adLoaded]
  );

  function addVisualizerStream(merger) {
    if (visualizerRef.current && visualizerRef.current.audioCanvas) {
      const canvasElm = visualizerRef.current.audioCanvas;
      const canvasStream = canvasElm.captureStream();

      merger.addStream(canvasStream, {
        x: Math.floor(
          (settings.advertisement.width - settings.audioSpectrum.width) / 2
        ),
        y: (settings.outputVideo.height - settings.audioSpectrum.height) / 2,
        width: settings.audioSpectrum.width,
        height: settings.audioSpectrum.height,
        mute: true
      });
    }
  }

  const loadLocalAdvertisement = function(event) {
    const advertisementVideo = advertisementRef.current;

    const file = event.target.files[0];
    if (file) {
      const type = file.type;
      setIsAudio(type.includes('audio'));
      let canPlay = advertisementVideo.canPlayType(type);
      if (canPlay === '') canPlay = 'no';
      const isError = canPlay === 'no';
      if (isError) {
        setFieldErrors(prev => ({
          ...prev,
          localAdvertisement: `Cannot play video of type ${type}`
        }));
        return;
      }

      const fileURL = URL.createObjectURL(file);
      setAdSrc(fileURL);
      setAdLoaded('local');
      setAdUrlInput(file.name);
    }
  };

  const loadExternalAdvertisement = function() {
    if (adUrlInput) {
      // fetch(adUrlInput)
      //   .then(r => r.blob())
      //   .then(blob => {
      //     const fileURL = URL.createObjectURL(blob);
      //     setAdSrc(fileURL);
      //   });
      setAdSrc(adUrlInput);
      const extension = adUrlInput
        .substr(adUrlInput.lastIndexOf('.') + 1)
        .toLowerCase();
      setIsAudio(
        ['wav', 'mp3', 'aiff', 'aac', 'ogg', 'wma'].includes(extension)
      );
      setAdLoaded('external');
    }
  };

  const clearAdvertisement = function() {
    setFieldErrors(prev => ({
      ...prev,
      advertisement: ''
    }));
    setAdUrlInput('');
    setAdSrc('');
    setAdReady(false);
    setAdLoaded('');
  };

  const onAdVideoReady = event => {
    setAdReady(true);
    // const videoEl = event.target;
  };

  // function stopCapture(merger) {
  //   const advertisement = advertisementRef.current;
  //   advertisement.pause();
  //   setRunning(false);
  //   // outputRef.current.srcObject.getTracks().forEach(track => track.stop());
  // }

  function wait(delayInMS) {
    return new Promise(resolve => setTimeout(resolve, delayInMS));
  }

  const startRecordingStream = useCallback(
    stream => {
      let recorder = new MediaRecorder(stream);
      let data = [];
      recorder.ondataavailable = event => data.push(event.data);
      recorder.start();

      let stopped = new Promise((resolve, reject) => {
        recorder.onstop = resolve;
        recorder.onerror = event => reject(event.name);
      });

      const ended = new Promise(resolve => {
        const advertisement = advertisementRef.current;
        advertisement.onended = async () => {
          if (isAudio) {
            await wait(1000);
          }
          resolve();
        };
      }).then(() => recorder.state === 'recording' && recorder.stop());

      return Promise.all([stopped, ended]).then(() => data);
    },
    [isAudio]
  );

  const startRecording = useCallback(async () => {
    const merger = new VideoStreamMerger({
      width: settings.outputVideo.width,
      height: settings.outputVideo.height
    });
    addChartStream(merger);
    if (!addAdvertisementStream(merger)) {
      // problem with capturing the advertisement media (video / audio)
      return;
    }
    if (isAudio) {
      addVisualizerStream(merger);
    }

    const advertisement = advertisementRef.current;
    advertisement.volume = 1;

    merger.start();
    const stream = merger.result;

    // make sure ad is ready and is positioned at beginning after restart:
    await advertisement
      .play()
      .then(advertisement.pause())
      .catch(async e => {
        await wait(500);
        await advertisement.play().then(() => advertisement.pause());
      });

    startRecordingStream(stream)
      .then(async recordedChunks => {
        setRunning(RUN_STATUS_CONVERTING);
        const recordedBlob = new Blob(recordedChunks, {
          type: 'video/webm'
        });

        // for direct download webm:
        // recording.src = URL.createObjectURL(recordedBlob);
        // downloadButton.href = recording.src;

        // downloadButton.href = URL.createObjectURL(recordedBlob);
        // downloadButton.download = 'RecordedVideo.webm';
        // downloadButton.click();

        const token = await getTokenSilently();
        await convertVideo(recordedBlob, token).catch(e => {
          console.error(e);
          setFieldErrors(prev => ({ ...prev, general: e.message }));
        });

        // console.log(
        //   'Successfully recorded ' +
        //     recordedBlob.size +
        //     ' bytes of ' +
        //     recordedBlob.type +
        //     ' media.'
        // );
        merger.destroy();
        setRunning(RUN_STATUS_COMPLETE);
      })
      .catch(e => {
        console.error(e);
        setFieldErrors(prev => ({ ...prev, general: e.message }));
      });

    await wait(500);
    await advertisement.play().catch(e => {
      console.error(e);
    });
  }, [
    addChartStream,
    addAdvertisementStream,
    startRecordingStream,
    isAudio,
    getTokenSilently
  ]);

  async function startClick() {
    setFieldErrors({});
    if (drawChart(true)) {
      setRunning(RUN_STATUS_RECORDING);
    }
  }

  useEffect(() => {
    const fps = 60;
    if (running === RUN_STATUS_RECORDING) {
      if (graphData.triggerRecord) {
        if (chartRef.current) {
          chartRef.current.chartInstance.update();
        }
        setGraphData(prev => ({ ...prev, triggerRecord: false }));
        ticker.current = setInterval(() => {
          setGraphData(prev => ({
            ...prev,
            percentWatch: getPercentWatch()
          }));
        }, 1000 / fps);
        startRecording();
      }
    } else if (ticker.current) {
      clearInterval(ticker.current);
    }
  }, [running, graphData.triggerRecord, startRecording]);

  function onChangeRange(event) {
    const value = +event.target.value;
    const { name } = event.target;
    if (name === 'yRangeMax') {
      setYAxisRange(prev => [prev[0], value]);
    } else {
      setYAxisRange(prev => [value, prev[1]]);
    }
  }
  return (
    <MainLayout title="Moment to moment recorder">
      <Container>
        <UserInputForm>
          <InputContainer>
            <FormControl component="fieldset">
              <FormLabel component="legend">Chart type</FormLabel>
              <RadioGroup
                aria-label="graph type"
                name="graph-type"
                value={graphType}
                onChange={e => setGraphType(e.target.value)}
              >
                <FormControlLabel
                  value="moment-to-moment"
                  control={<Radio />}
                  label="moment-to-moment"
                />
                <FormControlLabel
                  value="engagement"
                  control={<Radio />}
                  label="engagement"
                />
              </RadioGroup>
            </FormControl>
          </InputContainer>

          <InputContainer>
            <FormLabel component="legend">Y-axis range</FormLabel>
            <TextField
              name="yRangeMin"
              label="min"
              value={yAxisRange[0]}
              type="number"
              onChange={onChangeRange}
            />
            <TextField
              name="yRangeMax"
              label="max"
              value={yAxisRange[1]}
              type="number"
              onChange={onChangeRange}
            />
          </InputContainer>

          <InputContainer>
            <StyleTextField
              id="data-points"
              label="Data points"
              multiline
              rowsMax={4}
              value={m2mInput}
              onChange={e => setM2mInput(e.target.value)}
              // placeholder="-"
              InputLabelProps={{ shrink: true }}
              error={!!fieldErrors.m2mInput}
              helperText={
                fieldErrors.m2mInput ||
                'Data points in percentage, per second. Ie. 65% 59% 80% ...'
              }
            />
            <Button variant="contained" onClick={drawChart}>
              Draw
            </Button>
          </InputContainer>

          <InputContainer>
            <StyleTextField
              label="Advertisement"
              InputLabelProps={{ shrink: true }}
              value={adUrlInput}
              onChange={ev => setAdUrlInput(ev.target.value)}
              InputProps={{
                endAdornment:
                  adLoaded !== '' || adUrlInput !== '' ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear"
                        onClick={clearAdvertisement}
                        onMouseDown={e => {
                          clearAdvertisement();
                          e.preventDefault();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null
              }}
              onBlur={loadExternalAdvertisement}
              disabled={!!adLoaded}
              error={!!fieldErrors.advertisement}
              helperText={
                fieldErrors.advertisement ||
                'Enter the url of the advertisement or upload a file from your computer'
              }
            />

            <input
              id="ad-upload"
              type="file"
              hidden
              onChange={loadLocalAdvertisement}
            />
            <label htmlFor="ad-upload">
              {adLoaded === '' && adUrlInput === '' ? (
                <Button variant="contained" component="span">
                  Upload
                </Button>
              ) : null}
            </label>

            {adLoaded === '' && adUrlInput !== '' ? (
              <Button variant="contained" component="span">
                Load
              </Button>
            ) : null}
          </InputContainer>

          {fieldErrors.general ? (
            <Alert
              severity="error"
              onClose={() => setFieldErrors(prev => ({ ...prev, general: '' }))}
            >
              {fieldErrors.general}
            </Alert>
          ) : null}

          <InputContainer>
            <Button
              variant="contained"
              id="start"
              onClick={function(evt) {
                startClick();
              }}
              disabled={!adReady || !!running}
            >
              Start
            </Button>
            {running ? (
              <Alert severity="info">
                Please wait while your video will be recorded and converted.
                After the whole process is finished, your download will start
                automatically.
                <br />
                <br />
                {running === RUN_STATUS_RECORDING ? '** recording **' : null}
                {running === RUN_STATUS_CONVERTING
                  ? '** converting video to mp4 **'
                  : null}
              </Alert>
            ) : null}
            {/* <Button
          variant="contained"
          id="stop"
          onClick={stopCapture}
          disabled={!running}
        >
          Stop Capture
        </Button> */}
          </InputContainer>
        </UserInputForm>

        {/* <StyledOutputVideo autoPlay muted ref={outputRef}></StyledOutputVideo> */}

        <InputStreamContainer>
          <VideoContainer>
            {isAudio ? (
              <AudioSpectrumContainer>
                <AudioSpectrum
                  id="audio-canvas"
                  ref={visualizerRef}
                  height={settings.audioSpectrum.height}
                  width={settings.audioSpectrum.width}
                  audioId={'advertisement'}
                  capColor={colors.dvjRed}
                  capHeight={2}
                  meterWidth={settings.audioSpectrum.meterWidth}
                  meterCount={settings.audioSpectrum.meterCount}
                  meterColor={[
                    { stop: 0, color: colors.dvjRed },
                    { stop: 0.5, color: colors.grey },
                    { stop: 1, color: colors.dvjBlue }
                  ]}
                  gap={settings.audioSpectrum.meterGap}
                />
              </AudioSpectrumContainer>
            ) : null}
            <StyledVideo
              hidden={isAudio}
              src={adSrc}
              crossOrigin="anonymous"
              // onLoadedData={onAdVideoLoaded}
              onCanPlayThrough={onAdVideoReady}
              onError={() => {
                if (adSrc) {
                  setFieldErrors(prev => ({
                    ...prev,
                    advertisement: 'Cannot load video',
                    general: `You can only use media from dvjrg.com, dvj-insights.com or uploads.realityanalytics.com. If you want to 
                    use other sources, download the media to your computer first and then use the 
                    'upload' button.`
                  }));
                }
              }}
              // onPlay={() => console.log('playing')}
              // onEnded={onAdVideoEnded}
              ref={advertisementRef}
              id="advertisement"
              controls={false}
            />
          </VideoContainer>

          <M2M
            ref={chartRef}
            graphType={graphType}
            data={graphData.m2mValues}
            percentWatch={graphData.percentWatch}
            yMin={yAxisRange[0]}
            yMax={yAxisRange[1]}
            width={settings.chart.width}
            height={settings.chart.height}
          />
        </InputStreamContainer>
      </Container>
    </MainLayout>
  );
};

export default Moment2Moment;

/*
test data:

65% 59% 80% 81% 56% 44% 32% 23% 38% 51%
65% 59% 80% 81% 56% 44% 32% 23% 38% 51% 44% 32% 23% 38%

https://dvjrg.com/test.mp4
https://dvjrg.com/ING%20geef%20jezelf%20de%20ruimte%20RER15M_RADIO.mp3
https://static.videezy.com/system/resources/previews/000/004/059/original/Mountain_meadow_with_cows.mp4

*/
