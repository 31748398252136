import { readString } from 'react-papaparse';

function parseKinesisCsvData(papaParseResult, doSecondParse = false) {
  if (!papaParseResult) {
    return papaParseResult;
  }
  const convertedData = papaParseResult.map(row => {
    if (!doSecondParse) {
      return row.data;
    }
    // In some Kinesis exports the csv contains too many quotes and each row
    // needs to be parsed again individually:
    if (!row.data || row.data.length !== 1) {
      console.log('unexpected data format row.data');
      return row.data;
    }
    const convertedRow = readString(row.data[0]);
    if (convertedRow && convertedRow.data && convertedRow.data.length > 0) {
      return convertedRow.data[0];
    } else {
      console.warn('parse error', convertedRow);
      return [];
    }
  });
  return convertedData;
}

function getReferralKeysFromCsvData(papaParseResult) {
  const convertedData = parseKinesisCsvData(papaParseResult);
  if (!convertedData || convertedData.length < 1) {
    return [];
  }
  const headers = convertedData[0];
  const dataIndex = headers.findIndex(header => {
    return header.includes('QReferralKey');
  });
  if (dataIndex < 0) {
    throw new Error('ReferralKeys not found in data file');
  }
  return convertedData
    .slice(1)
    .filter(row => row.length > dataIndex && row[dataIndex])
    .map(row => row[dataIndex]);
}

export { parseKinesisCsvData, getReferralKeysFromCsvData };
