import axios from 'axios';

const api = axios.create({ baseURL: `/api/pabbl/v1/` });

function fetchCriteriaOptions(token) {
  return new Promise((resolve, reject) => {
    api
      .get('criteria', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.message ? `[${res.message}]` : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

export { fetchCriteriaOptions };
