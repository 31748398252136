import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Filters from '../../Table/MonthYearFilters';

const StyledDialog = styled(Dialog)`
  padding: 16px;
`;
function FilterDialog(props) {
  const { onCancel, open, value, onSubmit } = props;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleClose = () => {
    onCancel();
  };

  const handleApply = () => {
    onSubmit(localValue);
  };

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="dialog-title">Choose month</DialogTitle>
      <DialogContent>
        <Filters value={value} onChange={setLocalValue} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleApply} variant="contained">
          Apply
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
export default FilterDialog;
