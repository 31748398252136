import axios from 'axios';
import moment from 'moment';

const api = axios.create({ baseURL: `/api/pabbl/v1/` });

/*
survey type, see server\models\Pabbl\survey.js:
  surveyId: {
    type: String,
    unique: true,
    required: true
  },
  decipherId: {
    type: String,
    required: true
  },
  projectId: {
    type: String,
    required: false
  },
  pabblId: {
    type: String,
    required: false
  },
  title: {
    type: String,
    required: true
  },
  // status: oneOf(["standby","test","active","suspended","terminated"])
  status: {
    type: String,
    required: true
  },
  liveLink: {
    type: String,
    required: false
  },
  length: {
    type: Number,
    required: true
  },
  quota: {
    type: Number,
    required: false
  },
  startDate: {
    type: String,
    required: true
  },
  endDate: {
    type: String,
    required: true
  },
  quotas: {
    type: [quotaSchema]
  },
  targets: {
    type: [String],
    required: false
  },
  json: {
    type: String,
    required: true
  }
*/

export function fetchSurveys(token) {
  return new Promise((resolve, reject) => {
    api
      .get('surveys', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res.data.data) {
          resolve(res.data.data);
        } else {
          console.log(res);
          reject(new Error('Invalid result'));
        }
      })
      .catch(err => reject(err));
  });
}

export function fetchPabblSurveys(surveyId, token) {
  return new Promise((resolve, reject) => {
    api
      .get(`surveys/pabblData/${surveyId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res.data) {
          resolve(res.data.data);
        } else {
          console.log(res);
          reject(new Error('Invalid result'));
        }
      })
      .catch(err => reject(err));
  });
}

export function fetchSurveyData(surveyId, token) {
  return new Promise((resolve, reject) => {
    api
      .get(`surveys/${surveyId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res.data) {
          resolve(res.data.data);
        } else {
          console.log(res);
          reject(new Error('Invalid result'));
        }
      })
      .catch(err => reject(err));
  });
}

export const dateStringToIso = (dateStr, timeMode = 'begin') => {
  const dateMoment = moment(dateStr);
  if (timeMode === 'end') {
    dateMoment.endOf('day');
  } else {
    dateMoment.startOf('day');
  }
  return dateMoment.toISOString();
};

function formatSurveyDataForPabbl({ general, criteria, quotas }) {
  const formattedCriteria = Object.keys(criteria).map(id => {
    const codes = criteria[id];
    return { id, codes };
  });
  const formattedQuotas = quotas.map(quota => {
    return {
      id: quota.id,
      quota: quota.quota,
      criteria: Object.keys(quota.criteria).map(id => {
        return { id, codes: quota.criteria[id].value };
      })
    };
  });
  const pabblData = {
    criteria: formattedCriteria,
    endTime: dateStringToIso(general.endDate, 'end'),
    groups: general.groups,
    groupMode: general.groupMode,
    // surveyId and liveLink are handled by backend
    id: general.decipherId,
    length: general.length,
    quota: general.quota,
    quotas: formattedQuotas,
    startTime: dateStringToIso(general.startDate),
    status: general.status,
    title: general.title
  };
  if (general.targets && general.targets.length > 0) {
    pabblData.targets = general.targets;
  }
  if (general.groups && general.groups.length) {
    pabblData.groups = general.groups;
    pabblData.groupMode = general.groupMode;
  }
  return pabblData;
}

export function saveSurvey(surveyData, token) {
  // const { general, criteria, quotas } = surveyData;
  // general.targets
  const { general } = surveyData;
  const dataToStore = {
    decipherId: general.decipherId,
    projectId: general.projectId,
    title: general.title,
    status: general.status,
    length: general.length,
    quota: general.quota,
    startDate: general.startDate,
    endDate: general.endDate,
    pabblFormat: formatSurveyDataForPabbl(surveyData)
  };
  if (general.groups && general.groups.length) {
    dataToStore.groups = general.groups;
    dataToStore.groupMode = general.groupMode;
  }
  if (general.urlParameters && general.urlParameters.length) {
    dataToStore.urlParameters = general.urlParameters;
  }
  return new Promise((resolve, reject) => {
    api
      .post('surveys', dataToStore, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => reject(err));
  });
}

export function editSurvey(surveyId, surveyData, token) {
  // surveyData parameters:
  const updateFields = ['status', 'quota', 'quotas', 'startTime', 'endTime'];

  const dataToStore = {};
  updateFields.forEach(fieldName => {
    if (surveyData.hasOwnProperty(fieldName)) {
      switch (fieldName) {
        case 'startTime':
          dataToStore[fieldName] = dateStringToIso(surveyData[fieldName]);
          break;
        case 'endTime':
          dataToStore[fieldName] = dateStringToIso(
            surveyData[fieldName],
            'end'
          );
          break;
        default:
          dataToStore[fieldName] = surveyData[fieldName];
      }
    }
  });
  return new Promise((resolve, reject) => {
    api
      .patch(`surveys/pabblData/${surveyId}`, dataToStore, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => reject(err));
  });
}

/**
 * Save changes to survey data in own database
 * @param {String} surveyId
 * @param {Object} surveyData
 * @param {String} token
 */
export function editDbSurvey(surveyId, surveyData, token) {
  // surveyData parameters:
  const updateFields = ['urlParameters'];

  const dataToStore = {};
  updateFields.forEach(fieldName => {
    if (surveyData.hasOwnProperty(fieldName)) {
      switch (fieldName) {
        case 'startTime':
          dataToStore[fieldName] = dateStringToIso(surveyData[fieldName]);
          break;
        case 'endTime':
          dataToStore[fieldName] = dateStringToIso(
            surveyData[fieldName],
            'end'
          );
          break;
        default:
          dataToStore[fieldName] = surveyData[fieldName];
      }
    }
  });
  return new Promise((resolve, reject) => {
    api
      .patch(`surveys/dbData/${surveyId}`, dataToStore, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => reject(err));
  });
}

export function fetchGroups(token) {
  return new Promise((resolve, reject) => {
    api
      .get(`groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res.data) {
          resolve(res.data.data);
        } else {
          console.log(res);
          reject(new Error('Invalid result'));
        }
      })
      .catch(err => reject(err));
  });
}

/** backup, not in use: */
export function putSurvey(surveyId, surveyData, token) {
  const {
    status,
    quota,
    // quotas,
    startTime,
    endTime
  } = surveyData;
  const dataToStore = {
    status,
    quota,
    // quotas,
    startTime: dateStringToIso(startTime),
    endTime: dateStringToIso(endTime, 'end')
  };
  return new Promise((resolve, reject) => {
    api
      .put(`surveys/pabblData/${surveyId}`, dataToStore, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => reject(err));
  });
}
