import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '../../../../auth/react-auth0-spa';
import Table from '../../../Table';
import Papa from 'papaparse';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { fetchStatistics } from '../../../../models/pabbl/statistics';
import Filters from '../../../Table/MonthFilters';

const StyledButton = styled(Button)`
  margin-left: 16px;
`;
const ToolBlock = styled.div`
  display: flex;
`;

const Statistics = () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({ month: null });

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      const token = await getTokenSilently();
      const stats = await fetchStatistics(
        filters.month.year,
        filters.month.month,
        token
      ).catch(err => console.error(err));
      if (isMounted) {
        setData(stats || []);
      }
    };
    if (filters.month && filters.month.year && filters.month.month) {
      fetch();
    }

    return () => {
      isMounted = false;
    };
  }, [filters.month, getTokenSilently]);

  const downloadTable = () => {
    const csv = Papa.unparse(data, { delimiter: ';' });
    const dataStr = `data:text/csv;charset=utf-8,${csv}`;
    var tempLink = document.createElement('a');
    tempLink.href = dataStr;
    const fileName = `PabblData-${filters.month.year}-${filters.month.month}.csv`;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
  };

  const changeMonth = useCallback(
    newMonth => setFilters(prev => ({ ...prev, month: newMonth })),
    []
  );

  const columns = [
    {
      label: 'survey id',
      data: 'surveyId',
      rowHeader: true
    },
    {
      label: 'enter',
      align: 'right',
      data: 'enter'
    },
    {
      label: 'exit',
      align: 'right',
      data: 'exit'
    },
    {
      label: 'complete',
      align: 'right',
      data: 'complete'
    },
    {
      label: 'notQualified',
      align: 'right',
      data: 'notQualified'
    }
  ];

  return (
    <div>
      <h3>Statistics</h3>
      <ToolBlock>
        <Filters value={filters.month} onChange={changeMonth} />
        <StyledButton
          onClick={downloadTable}
          variant="contained"
          color="primary"
        >
          Download
        </StyledButton>
      </ToolBlock>

      <Table data={data} columns={columns} rowKey="surveyId" />
    </div>
  );
};

export default Statistics;
