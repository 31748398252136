import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import MainLayout from './layout/MainLayout';

const propTypes = {};
const defaultProps = {};

const ButtonContainer = styled.div`
  display: flex;
`;
const styles = {
  toolButton: {
    padding: '20px 10px',
    marginRight: 24,
    width: 200,
    height: 200,
    cursor: 'pointer'
  }
};

const ToolButton = ({ to, label }) => {
  return (
    <Link to={to}>
      <button style={styles.toolButton}>{label}</button>
    </Link>
  );
};

const ToolButtonExternal = ({ to, label }) => {
    return (
        <a href={to}>
            <button style={styles.toolButton}>{label}</button>
        </a>
    );
};

const Home = () => {
  return (
    <MainLayout>
      <h1>Researcher tools</h1>

      <ButtonContainer>
        <ToolButton
          to="/pabbl"
          label={
            <>
              <h3>Pabbl tool</h3>
              <p>Send surveys to the Pabbl API</p>
            </>
          }
        />
        <ToolButton
          to="/m2m"
          label={
            <>
              <h3>Moment-to-moment tool</h3>
              <p>
                Create a video of an advertisement with moment-to-moment or
                engagement graph
              </p>
            </>
          }
        />
      <ToolButtonExternal
          to="https://mtm.researcher-tools.dvj-insights.com"
          label={
              <>
                  <h3>New MTM/Engagement tool</h3>
                  <p>
                      Create multiple videos of an advertisement with moment-to-moment or
                      engagement graph
                  </p>
              </>
          }
      />
        <ToolButton
          to="/paneldata/monthly-count"
          label={
            <>
              <h3>Panel data</h3>
              <p>Monthly count of panel statistics</p>
            </>
          }
        />
      </ButtonContainer>
    </MainLayout>
  );
};
Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
