import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../../../auth/react-auth0-spa';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import SurveyList from './SurveyList';
import ViewSurvey from './ViewSurvey';
import CreateSurvey from './CreateSurvey';
import PreviewJson from './PreviewJson';

import { fetchCheckDecipherQuotas } from '../../../models/pabbl/quotas';
import {
  fetchSurveys,
  fetchSurveyData,
  saveSurvey,
  editSurvey
} from '../../../models/pabbl/survey';

const StyledLink = styled(Link)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const VIEWMODE_LIST = 0;
const VIEWMODE_ADD = 1;
const VIEWMODE_JSON = 99;

const SurveyView = ({ routeTo, view, survey }) => {
  const [surveys, setSurveys] = useState([]);
  const [decipherQuotas, setDecipherQuotas] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [viewMode, setViewMode] = useState(VIEWMODE_LIST);
  const [message, setMessage] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [json, setJson] = useState('');
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    let mounted = true;
    setIsBusy(true);
    async function doFetchSurveys() {
      const token = await getTokenSilently();
      fetchSurveys(token)
        .then(fetchedSurveys => {
          if (mounted) {
            setSurveys(fetchedSurveys.reverse());
          }
        })
        .catch(err => {
          showMessage('Error: Unable to get survey list from database');
          console.warn(err);
        })
        .finally(() => {
          if (mounted) {
            setIsBusy(false);
          }
        });
    }
    doFetchSurveys();
    return () => {
      mounted = false;
    };
  }, [getTokenSilently]);

  const onSelectSurvey = selection => {
    if (selection) {
      routeTo(`view/${selection.surveyId}`);
    } else {
      routeTo('');
    }
  };

  useEffect(() => {
    async function fetchQuotas() {
      if (selectedSurvey && selectedSurvey.decipherId && view !== 'add') {
        const token = await getTokenSilently();
        const fetchedQuotas = await fetchCheckDecipherQuotas(
          selectedSurvey.decipherId,
          token
        );
        setDecipherQuotas(fetchedQuotas);
      }
      switch (view) {
        case 'add':
          setViewMode(VIEWMODE_ADD);
          break;
        case 'view':
          setViewMode(VIEWMODE_LIST);
          break;
        default:
          setViewMode(VIEWMODE_LIST);
      }
    }
    fetchQuotas();
  }, [getTokenSilently, view, selectedSurvey]);

  useEffect(() => {
    async function fetchSelectedSurveyData() {
      if (survey && view !== 'add') {
        try {
          const token = await getTokenSilently();
          const fetchedData = await fetchSurveyData(survey, token);
          setSelectedSurvey(fetchedData);
        } catch (err) {
          console.warn(err);
        }
      }
    }
    if (survey /*&& viewMode === VIEWMODE_LIST*/) {
      fetchSelectedSurveyData();
    } else {
      setSelectedSurvey(null);
    }
  }, [getTokenSilently, survey, view]);

  function refreshSurveyData() {
    // return new Promise((resolve, reject) => {
    const handleError = error => {
      console.warn('Fout', error);
      // reject(error);
    };
    if (!survey) {
      // reject(new Error('No survey selected'));
      return;
    }
    getTokenSilently()
      .then(token => {
        fetchSurveyData(survey, token)
          .then(fetchedData => {
            setSelectedSurvey(fetchedData);
            // resolve(fetchedData);
          })
          .catch(handleError);
      })
      .catch(handleError);
    // });
  }

  function editPabblData(surveyId, changedData) {
    return new Promise(async (resolve, reject) => {
      const token = await getTokenSilently();
      editSurvey(surveyId, changedData, token)
        .then(result => {
          refreshSurveyData();
          resolve(true);
        })
        .catch(err => {
          console.warn('Error while submitting changed values', err);
          reject(err);
        });
    });
  }

  async function addSurvey(surveyData) {
    setIsBusy(true);

    const token = await getTokenSilently();
    // Save survey to database:
    saveSurvey(surveyData, token)
      .then(saveResult => {
        // const savedSurvey = saveResult.data;
        showMessage('Survey successfully saved.', 'success');
        setSurveys(prevSurveys => [...prevSurveys, saveResult.data]);
        if (saveResult && saveResult.data && saveResult.data.json) {
          setJson(saveResult.data.json);
        } else {
          showMessage('No json data found in response', 'warning');
        }

        setViewMode(VIEWMODE_JSON);
      })
      .catch(saveError => {
        console.warn('saveError:', saveError);
        showMessage('Something went wrong while saving your data!', 'error');
      })
      .finally(() => {
        setIsBusy(false);
      });
  }

  function showMessage(text, type = 'info') {
    setMessage({ text, type });
  }
  function clearMessage() {
    setMessage(null);
  }

  function renderListView() {
    return (
      <>
        <SurveyList surveys={surveys} onSelectSurvey={onSelectSurvey} />
        <IconButton
          aria-label="add"
          color="primary"
          onClick={() => {
            routeTo('add');
          }}
        >
          {isBusy ? <CircularProgress /> : <AddIcon />}
        </IconButton>
      </>
    );
  }

  function renderSingleSurvey() {
    return (
      <>
        <StyledLink
          component="button"
          variant="body2"
          onClick={() => {
            onSelectSurvey(null);
          }}
        >
          Back to list
        </StyledLink>
        <ViewSurvey
          values={selectedSurvey}
          editValues={(param, newValue) =>
            setSelectedSurvey(prevState => ({
              ...prevState,
              [param]: newValue
            }))
          }
          decipherQuotas={decipherQuotas}
          editPabblData={editPabblData}
        />
      </>
    );
  }

  function renderJsonView() {
    return (
      <>
        <PreviewJson json={json} />
      </>
    );
  }

  function renderAddSurvey() {
    return (
      <>
        <CreateSurvey onCancel={() => routeTo('')} onSubmit={addSurvey} />
      </>
    );
  }

  let content = null;
  let title = 'Survey list';
  switch (viewMode) {
    case VIEWMODE_LIST:
      if (selectedSurvey) {
        content = renderSingleSurvey();
        title = `Survey ${selectedSurvey.surveyId || selectedSurvey.id}`;
      } else {
        content = renderListView();
      }
      break;
    case VIEWMODE_ADD:
      title = 'Add survey';
      content = renderAddSurvey();
      break;
    case VIEWMODE_JSON:
      title = 'Survey saved';
      content = renderJsonView();
      break;
    default:
      content = renderListView();
  }

  return (
    <div>
      <h3>{title}</h3>
      {message ? (
        <Alert severity={message.type} onClose={clearMessage}>
          {message.text}
        </Alert>
      ) : null}
      {content}
    </div>
  );
};
export default SurveyView;
