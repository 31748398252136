import React from 'react';
// import PropTypes from 'prop-types';
import { Switch, useRouteMatch } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';

import PrivateRoute from '../../../auth/PrivateRoute';

import MainLayout from '../../layout/MainLayout';

import PanelMonthlyCount from './PanelMonthlyCount';

const menuItems = [
  {
    label: 'Monthly count',
    to: '/paneldata/monthly-count',
    icon: <ListIcon />
  }
];

const Layout = props => (
  <MainLayout title="Panel data" menuItems={menuItems} {...props} />
);

const PanelData = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        {/* tool routes */}
        <PrivateRoute
          key="monthly-count"
          path={`${path}/monthly-count`}
          render={props => {
            const { match } = props;
            const { params } = match;
            return (
              <Layout menuItems={[]}>
                <PanelMonthlyCount {...params} />
              </Layout>
            );
          }}
        />
        {/* home / fallback */}
        <PrivateRoute
          path={path}
          render={() => {
            return (
              <Layout>
                <h1>Panel data</h1>
                <p>Tool to get data from Kinesis Panel</p>
              </Layout>
            );
          }}
        ></PrivateRoute>
      </Switch>
    </div>
  );
};

export default PanelData;
