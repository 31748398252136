const validateField = (fieldId, valuesToValidate) => {
  switch (fieldId) {
    case 'endDate':
      if (!valuesToValidate.endDate) {
        return 'This field can not be empty!';
      } else if (
        valuesToValidate.startDate &&
        valuesToValidate.endDate < valuesToValidate.startDate
      ) {
        return 'End date must be greater than start date';
      }
      return '';
    default:
      if (valuesToValidate[fieldId]) {
        return '';
      } else {
        return 'This field can not be empty!';
      }
  }
};
export default validateField;
