import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      align: PropTypes.string,
      rowHeader: PropTypes.bool
    })
  )
};
const defaultProps = {
  data: [],
  align: 'left',
  rowHeader: false
};
const DefaultTable = ({ data, columns, rowKey }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(col => {
              const options = col.align ? { align: col.align } : {};
              return (
                <TableCell key={col.data} {...options}>
                  {col.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((rowData, index) => {
            let key = index;
            if (rowKey) {
              key =
                typeof rowKey === 'function'
                  ? rowKey(rowData)
                  : rowData[rowKey];
            }

            return (
              <TableRow key={key}>
                {columns.map(col => {
                  const colKey = col.data;
                  const options = col.align ? { align: col.align } : {};
                  if (col.rowHeader) {
                    options.component = 'th';
                    options.scope = 'row';
                  }
                  const data =
                    typeof col.data === 'function'
                      ? col.data(rowData)
                      : rowData[col.data];
                  return (
                    <TableCell key={`${key}-${colKey}`} {...options}>
                      {data}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
DefaultTable.propTypes = propTypes;
DefaultTable.defaultProps = defaultProps;

export default DefaultTable;
