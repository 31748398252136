import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAuth0 } from '../../auth/react-auth0-spa';

import { Link } from 'react-router-dom';
// import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// import breakpoints from '../../config/style/breakpoints';
import styleSettings from '../../config/style/styleSettings';

const { drawerWidth } = styleSettings;

const MainContainer = styled.div`
  display: flex;
`;
/* @media ${breakpoints.tablet} {
      width: calc(100% - ${drawerWidth}px);
      margin-left: ${drawerWidth},
  } */
const StyledAppBar = styled(({ shift, ...other }) => <AppBar {...other} />)`
  transition: margin 225ms, width 225ms;
  width: ${props => (props.shift ? `calc(100% - ${drawerWidth}px)` : null)};
  margin-left: ${props => (props.shift ? drawerWidth : 0)}px;
`;
const MenuButton = styled(({ hide, ...other }) => <IconButton {...other} />)`
  margin-right: 16px;
  display: ${props => (props.hide ? 'none' : null)};
`;
const StyledDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;

  & .MuiPaper-root {
    width: ${drawerWidth}px;
  }
`;
const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  justify-content: flex-end;
  min-height: 56px;
`;
const ToolbarContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const AuthBlock = styled.div`
  text-align: right;
`;

const ContentContainer = styled.main`
  flex-grow: 1;
  padding: 12px;
  transition: margin 225ms;
  margin-left: ${props => (props.shift ? 0 : -drawerWidth)}px;
  overflow-x: auto;
`;

function ListItemLink(props) {
  const { icon, label, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={label} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

function MainLayout({ children, menuItems, title }) {
  const hasMainMenu = !!(menuItems && menuItems.length);
  const [open, setOpen] = React.useState(hasMainMenu);
  // const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    // loading,
    user
  } = useAuth0();

  return (
    <MainContainer>
      <StyledAppBar position="fixed" shift={open}>
        <Toolbar>
          {hasMainMenu || open ? (
            <MenuButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              hide={open}
            >
              <MenuIcon />
            </MenuButton>
          ) : null}
          <ToolbarContent>
            <h3>{title}</h3>

            <AuthBlock>
              {!isAuthenticated && (
                <button onClick={() => loginWithRedirect({})}>Log in</button>
              )}

              {isAuthenticated && (
                <>
                  {user ? <div>{`${user.name}`}</div> : null}
                  <button
                    onClick={() =>
                      logout({
                        returnTo:
                          window.location.protocol + '//' + window.location.host
                      })
                    }
                  >
                    Log out
                  </button>
                </>
              )}
            </AuthBlock>
          </ToolbarContent>
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer variant="persistent" anchor="left" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map(menuItem => {
            const { label, icon, to } = menuItem;
            return (
              <ListItemLink key={label} to={to} label={label} icon={icon} />
            );
          })}
        </List>
      </StyledDrawer>
      <ContentContainer shift={open}>
        <DrawerHeader />
        {children}
      </ContentContainer>
    </MainContainer>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired
    })
  ),
  title: PropTypes.node
};
MainLayout.defaultProps = {
  children: null,
  menuItems: [],
  title: 'Researcher tools'
};

export default MainLayout;
