import React, { useState } from 'react';
import styled from 'styled-components';
import { CSVReader } from 'react-papaparse';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { getReferralKeysFromCsvData } from '../../../models/pabbl/kinesis';

const TargetListContainer = styled.div`
  word-break: break-all;
  margin-top: 16px;
`;

const StyledButton = styled(IconButton)`
  display: inline-block;
`;

export const TargetList = ({ targets, handleClear }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleVisibility = () => {
    setShowAll(prev => !prev);
  };

  if (!targets) {
    return null;
  }
  return (
    <TargetListContainer>
      targets ({targets.length}): [
      {showAll
        ? targets.join(', ')
        : `${targets.slice(0, 3).join(', ')}${
            targets.length > 3 ? ', ...' : ''
          }`}
      ]
      {targets.length > 3 ? (
        <StyledButton
          aria-label="delete"
          color="primary"
          onClick={toggleVisibility}
        >
          {showAll ? <VisibilityOff /> : <Visibility />}
        </StyledButton>
      ) : null}
      {handleClear ? (
        <StyledButton aria-label="delete" color="primary" onClick={handleClear}>
          <DeleteIcon />
        </StyledButton>
      ) : null}
    </TargetListContainer>
  );
};

const Reader = ({ onDrop, onError }) => {
  return (
    <CSVReader
      onDrop={onDrop}
      onError={onError}
      config={{
        header: false
      }}
      style={{
        background: 'linear-gradient(rgb(238, 238, 238), rgb(221, 221, 221))',
        color: '#303030'
      }}
    >
      <span>Drop CSV file here or click to upload.</span>
    </CSVReader>
  );
};

const Targets = ({ targets, setTargets }) => {
  const [error, setError] = useState(false);
  const [readerKey, setReaderKey] = useState(1);

  const handleOnDrop = data => {
    const referralKeys = getReferralKeysFromCsvData(data);
    setTargets(referralKeys);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.warn(err);
    setError(err);
  };

  const handleClear = () => {
    setTargets(null);
    // fileInputEl.current.value = '';
    // rerender the reader to clear contents:
    setReaderKey(prev => prev + 1);
  };

  return (
    <div>
      <p>
        If you want to show the survey to a selected set of (already known)
        Pabbl users you can create a sample for them in Kinesis Panel. Send
        invitations too. Then export the panelists in the sample to a csv file
        that contains at least the datapoint 'QReferralKey`. Upload this csv
        file by clicking or dropping below.
      </p>

      {error ? (
        <Alert severity="error" onClose={() => setError(false)}>
          {error}
        </Alert>
      ) : null}
      <Reader key={readerKey} onDrop={handleOnDrop} onError={handleOnError} />
      <TargetList targets={targets} handleClear={handleClear} />
    </div>
  );
};

export default Targets;
