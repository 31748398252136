import axios from 'axios';

const api = axios.create({ baseURL: `/api/paneldata/v1/` });

/**
 * @obsolete
 * @param {*} token
 */
function fetchPanelMonthlyCount(token) {
  const CancelToken = axios.CancelToken;
  let cancel;
  const thePromise = new Promise((resolve, reject) => {
    api
      .get('test', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      })
      .then(res => {
        if (res && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.message ? `[${res.message}]` : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
  thePromise.cancel = cancel;
  return thePromise;
}

/**
 * @obsolete
 * @param {*} year
 * @param {*} month
 * @param {*} token
 */
function fetchPanels(year, month, token) {
  let url = 'panels';
  if (year && typeof month !== 'undefined') {
    url = `${url}/${year}/${month}`;
  }
  return new Promise((resolve, reject) => {
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.message ? `[${res.message}]` : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

/**
 * call to /panelstats/:year/:month
 * Fetches stored panel statistics from the database
 * @param {Number} year
 * @param {Number} month - ! 0=January, 1=February, ..., 11=December
 * @param {*} token
 */
function fetchPanelStats(year, month, token) {
  let url = 'panelstats';
  if (year && typeof month !== 'undefined') {
    url = `${url}/${year}/${month}`;
  }
  return new Promise((resolve, reject) => {
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res && res.data && res.data.data) {
          const stats = res.data.data.map(panel => {
            return {
              ...panel,
              updatedAt: new Date(panel.updatedAt)
            };
          });
          resolve(stats);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.message ? `[${res.message}]` : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

/**
 * call to /run-panelstats/:year/:month
 * Starts process to fetch panel statistics from Kinesis and store or update to
 * database (long running process, don't call too often!)
 * @param {Number} year
 * @param {Number} month - ! 0=January, 1=February, ..., 11=December
 * @param {*} token
 */
function runPanelStats(year, month, token) {
  let url = 'run-panelstats';
  if (year && typeof month !== 'undefined') {
    url = `${url}/${year}/${month}`;
  }
  return new Promise((resolve, reject) => {
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.status &&
          res.data.status === 'success'
        ) {
          resolve(true);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.message ? `[${res.message}]` : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

export { fetchPanelMonthlyCount, fetchPanels, fetchPanelStats, runPanelStats };

/*
// kept for reference to cancelable call:
// /panels/:panelid/:year/:month
function fetchPanelStats(panelid, year, month, token) {
  const CancelToken = axios.CancelToken;
  let cancel;
  let url = 'panels';
  if (!panelid || !year || typeof month === 'undefined') {
    throw new Error('Invalid parameters fetchPanelStats');
  }
  url = `${url}/${panelid}/${year}/${month}`;
  const cancelablePromise = new Promise((resolve, reject) => {
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      })
      .then(res => {
        if (res && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.message ? `[${res.message}]` : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
  cancelablePromise.cancel = cancel;
  return cancelablePromise;
}
*/
