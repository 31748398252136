import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField, MenuItem } from '@material-ui/core';

export const StyledTextField = styled(TextField)`
  margin: 4px 0px;
`;

export const numberType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);

const SurveyField = ({
  fieldId,
  value,
  onChange,
  readonly,
  fieldError,
  idPrefix = ''
}) => {
  const inputId = `field-${idPrefix}${fieldId}`;
  switch (fieldId) {
    case 'status': {
      const label = 'Status';
      if (readonly) {
        return (
          <StyledTextField
            id={inputId}
            label={label}
            value={value}
            fullWidth
            InputProps={{
              readOnly: true
            }}
          />
        );
      }
      return (
        <StyledTextField
          id={inputId}
          label={label}
          value={value}
          onChange={onChange}
          fullWidth
          error={!!fieldError}
          helperText={fieldError}
          select
        >
          {['standby', 'test', 'active', 'suspended', 'terminated'].map(
            option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )
          )}
        </StyledTextField>
      );
    }
    case 'startTime': {
      const label = 'Date from';
      return (
        <StyledTextField
          id={inputId}
          label={label}
          value={value}
          onChange={onChange}
          fullWidth
          error={!!fieldError}
          helperText={fieldError}
          type={readonly ? 'text' : 'date'}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: readonly
          }}
        />
      );
    }
    case 'endTime': {
      const label = 'Date to';
      return (
        <StyledTextField
          id={inputId}
          label={label}
          value={value}
          onChange={onChange}
          fullWidth
          error={!!fieldError}
          helperText={fieldError}
          type={readonly ? 'text' : 'date'}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: readonly
          }}
        />
      );
    }
    case 'quota': {
      const label = 'Quota (overall)';
      return (
        <StyledTextField
          id={inputId}
          label={label}
          value={value}
          onChange={onChange}
          fullWidth
          error={!!fieldError}
          helperText={fieldError}
          type={readonly ? 'text' : 'number'}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: readonly
          }}
        />
      );
    }
    default:
      throw new Error('Unknown fieldId supplied to SurveyFields');
  }
};

export default SurveyField;

//   <StyledTextField
//     id="endDate"
//     label="Date to"
//     type="date"
//     InputLabelProps={{
//       shrink: true
//     }}
//     value={values.endDate}
//     onChange={fieldChange('endDate')}
//     fullWidth
//     error={!!fieldErrors.endDate}
//     helperText={fieldErrors.endDate}
//   />
