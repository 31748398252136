import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Divider from '@material-ui/core/Divider';
import Criterion from './Criterion';

import { propTypes as criteriaStatePropTypes } from './SurveyStateManager';

import { dvjRed } from '../../../config/style/colors';

const propTypes = {
  criteria: criteriaStatePropTypes.availableCriteria,
  state: criteriaStatePropTypes.criteriaValues,
  setState: PropTypes.func.isRequired
};
const defaultProps = {};

const MoreLink = styled.div`
  cursor: pointer;

  &:hover {
    color: ${dvjRed};
  }
`;

const Criteria = ({ criteria, state, setState }) => {
  const [compactState, setCompactState] = useState(true);
  const toggleCompactState = () => {
    setCompactState(prev => !prev);
  };

  let filteredCriteria;
  if (compactState) {
    filteredCriteria = criteria.filter(
      criterion =>
        ['age', 'gender'].includes(criterion.key) ||
        Object.keys(state).includes(criterion.key)
    );
  } else {
    filteredCriteria = criteria;
  }

  return (
    <div>
      {filteredCriteria.map((criterion, i) => {
        const criterionState = state[criterion.key];
        return (
          <React.Fragment key={criterion.key}>
            <Criterion
              id={criterion.key}
              {...criterion}
              value={criterionState}
              setValue={val => setState(criterion.key, val)}
            />
            {i < filteredCriteria.length - 1 ? <Divider /> : null}
          </React.Fragment>
        );
      })}
      <MoreLink onClick={toggleCompactState}>
        {compactState ? 'more...' : 'less..'}
      </MoreLink>
    </div>
  );
};
Criteria.propTypes = propTypes;
Criteria.defaultProps = defaultProps;
export default Criteria;
