import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  immutableArrayChangeItem,
  immutableArrayRemoveItem
} from '../../../helpers/helperFunctions';
import { StyledTextField } from './SurveyFields';

const ExplanationBlock = styled.div`
  font-size: 80%;
  margin-bottom: 16px;
`;

const ErrorBlock = styled.div`
  /* font-size: 80%; */
  margin-bottom: 16px;
  color: #f44336;
`;

const StyledDeleteButton = styled(IconButton)`
  vertical-align: bottom;
`;

const StyledKeyInput = styled(StyledTextField)`
  max-width: 40%;
`;
const StyledValueInput = styled(StyledTextField)`
  max-width: 40%;
  margin-left: 16px;
`;

const defaultExplanation = (
  <>
    If applicable, add url parameters here. Ie. key: 'co' value: 'NL'.
    <br />
    After creating the survey with this tool, go to your project in Kinesis
    panel. Find the campaign named '[auto] existing Pabbl users' and make sure
    that it has the same url parameters.
    <br />
    Don't set the status to live before you have checked the url parameters in
    Kinesis panel!
  </>
);

const emptyParam = { key: '', value: '' };
const UrlParameters = ({
  parameters,
  setParameters,
  errorText,
  explanation = defaultExplanation
}) => {
  const onChangeInput = (i, type) => event => {
    const value = event.target.value;
    if (i >= parameters.length) {
      setParameters([...parameters, { ...emptyParam, [type]: value }]);
    } else {
      setParameters(
        immutableArrayChangeItem(parameters, i, item => ({
          ...item,
          [type]: value
        }))
      );
    }
  };

  const handleDeleteParameter = i => {
    setParameters(immutableArrayRemoveItem(parameters, i));
  };

  const showEmpty =
    parameters.length === 0 || parameters[parameters.length - 1]['key'] !== '';
  const emptyParams = showEmpty ? [emptyParam] : [];

  return (
    <div>
      <ExplanationBlock>{explanation}</ExplanationBlock>
      {[...parameters, ...emptyParams].map((param, i) => {
        return (
          <div key={i}>
            <StyledKeyInput
              id={`url_parameter_key_${param.key}`}
              label="key"
              value={param.key}
              onChange={onChangeInput(i, 'key')}
            />
            <StyledValueInput
              id={`url_parameter_value_${param.key}`}
              label="value"
              value={param.value}
              onChange={onChangeInput(i, 'value')}
            />
            {i < parameters.length && (
              <StyledDeleteButton
                aria-label="delete"
                color="primary"
                onClick={() => handleDeleteParameter(i)}
              >
                <DeleteIcon />
              </StyledDeleteButton>
            )}
          </div>
        );
      })}
      <ErrorBlock>{errorText}</ErrorBlock>
    </div>
  );
};

export default UrlParameters;
