import React, { useEffect, useState, useRef } from 'react';
import {
  enterSurvey,
  registerPageLeave
} from '../../../../models/pabbl/callback';

// example: http://localhost:3000/pabbl/surveylink-callback?referralKey=123457
//https://dvj.decipherinc.com/survey/selfserve/2144/200215?programId=139-0-11365-1250&referralKey=DVJ_Insights-testuser-001711656254&signature=5T2kCu9Sms9Qx1m1OmDyQjI8u_UdFE5JAFcU76FUyPg&age=25
// https://researcher-tools.dvj-insights.com/pabbl/surveylink-callback?surveyId=xxxxx&referralKey=123457
// ?programId={programId}&referralKey={referralKey}&signature={signature}

const SurveyLinkCallback = ({
  referralKey,
  surveyId,
  programId,
  signature,
  age,
  gender,
  testMode
}) => {
  const [redirectLink, setRedirectLink] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  function redirectTo(url) {
    window.location.replace(url);
  }

  const sessionIdRef = useRef(null);

  // run once:
  useEffect(() => {
    const enterTime = Date.now();
    const unloadEvent = () => {
      const timeOnPage = Date.now() - enterTime;
      registerPageLeave(timeOnPage, sessionIdRef.current, {
        referralKey,
        surveyId,
        testMode
      });
    };
    window.addEventListener('beforeunload', unloadEvent);

    if (referralKey && surveyId) {
      enterSurvey(surveyId, referralKey, {
        programId,
        signature,
        age,
        gender,
        testMode
      })
        .then(data => {
          const { surveyLink, sessionId } = data;
          console.log('surveyLink:', surveyLink);
          /* We save the link in the state to give the user the oppotunity to go to 
          url manually when an automatic redirect is not working */
          sessionIdRef.current = sessionId;
          setRedirectLink(surveyLink);
        })
        .catch(error => {
          setErrorMessage(error.message ? error.message : error);
        });
    }

    return () => {
      window.removeEventListener('beforeunload', unloadEvent);
    };
  }, [referralKey, surveyId, programId, signature, age, gender, testMode]);

  useEffect(() => {
    if (redirectLink) {
      redirectTo(redirectLink);
    }
  }, [redirectLink]);

  return (
    <div>
      <div>
        Een moment geduld altublieft, u wordt binnen enkele seconden automatisch
        doorgestuurd naar het onderzoek...
      </div>
      {redirectLink ? (
        <div>
          Indien u niet automatisch wordt doorgestuurd klik dan{' '}
          <a href={redirectLink}>hier</a>
        </div>
      ) : null}
      {referralKey && surveyId ? null : (
        <div>
          Het lijkt er op dat er enkele parameters ontbreken in de url. Wij
          kunnen u helaas niet doorsturen naar de vragenlijst. Excuses voor het
          ongemak.
        </div>
      )}
      {errorMessage ? (
        <div>
          <p>
            Het lijkt erop dat er een fout is opgetreden, excuses voor het
            ongemak.
          </p>
          <pre>{errorMessage}</pre>
        </div>
      ) : null}
    </div>
  );
};

export default SurveyLinkCallback;
