import React, { useEffect, useState } from 'react';
import { exitSurvey } from '../../../../models/pabbl/callback';

const ExitSurveyCallback = ({
  // surveyId,
  panelist_hash,
  eventKey,
  dvj_session
  // ...restQuery
}) => {
  const [message, setMessage] = useState(false);
  function redirectTo(url) {
    // console.log('redirect to', url);
    window.location.replace(url);
  }

  useEffect(() => {
    exitSurvey(panelist_hash, eventKey, dvj_session)
      .then(data => {
        const redirectUrl = data.url;
        const testMode = data.testMode;
        // eslint-disable-next-line eqeqeq
        if (testMode == 2) {
          setMessage(
            `You are testing a link. Normally the respondent would be redirected to: ${redirectUrl}`
          );
        } else {
          redirectTo(redirectUrl);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [eventKey, panelist_hash, dvj_session]);

  // console.log('panelist_hash:', panelist_hash);
  // console.log('eventKey:', eventKey);
  // console.log('restQuery', restQuery);
  return (
    <div>
      <p>
        Please wait until you are being redirected to the Pabbl callback link
        ...
      </p>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ExitSurveyCallback;
