import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.node,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled'])
};
const defaultProps = {
  options: [],
  label: null,
  variant: 'outlined'
};

const MultiSelectWithCreate = ({
  label,
  variant,
  options,
  value,
  setValue,
  ...passedProps
}) => {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue, action, current) => {
        if (
          action === 'select-option' &&
          current &&
          current.option &&
          current.option.inputValue
        ) {
          // Create a new value from the user input
          setValue([...newValue.slice(0, -1), current.option.inputValue]);
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '' && !options.includes(params.inputValue)) {
          filtered.push({
            inputValue: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }

        return filtered;
      }}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
      }}
      renderOption={option =>
        typeof option === 'string' ? option : option.label
      }
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant={variant}
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField {...params} label={label} variant={variant} />
      )}
      {...passedProps}
    />
  );
};
MultiSelectWithCreate.propTypes = propTypes;
MultiSelectWithCreate.defaultProps = defaultProps;
export default MultiSelectWithCreate;
