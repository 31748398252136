import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, CircularProgress, Divider } from '@material-ui/core';
import SurveyField, { StyledTextField, numberType } from './SurveyFields';
import validateField from './SurveyFields/validate';
import PreviewJson from './PreviewJson';

const propTypes = {
  pabblData: PropTypes.shape({
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    length: numberType,
    quota: numberType,
    liveLink: PropTypes.string,
    points: numberType,
    callBackLink: PropTypes.string,
    redirectLink: PropTypes.string,
    status: PropTypes.string,
    id: PropTypes.string
  }),
  editPabblData: PropTypes.func
};

const ButtonContainer = styled.div`
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  margin-right: 16px;
`;

const MODE_VIEW = 0;
const MODE_EDIT = 1;
const MODE_SUBMIT = 2;

function convertDataForEdit(pabblData) {
  return {
    ...pabblData,
    startTime: pabblData.startTime ? pabblData.startTime.slice(0, 10) : '',
    endTime: pabblData.endTime ? pabblData.endTime.slice(0, 10) : ''
  };
}

const NonInputFieldLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
`;
const NonInputField = ({ label, children }) => {
  return (
    <div>
      <NonInputFieldLabel>{label}</NonInputFieldLabel>
      {children}
      <Divider />
    </div>
  );
};

const SurveyPabblData = ({ pabblData, refreshPabblData, editPabblData }) => {
  const [editMode, setEditMode] = useState(MODE_VIEW);
  const [editValues, setEditValues] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [hasChangedValues, setHasChangedValues] = useState(false);

  function startEdit() {
    setEditValues(convertDataForEdit(pabblData));
    setHasChangedValues(false);
    setEditMode(MODE_EDIT);
  }
  function cancelEdit() {
    setEditMode(MODE_VIEW);
  }

  async function submitEdit() {
    if (validateForm()) {
      setEditMode(MODE_SUBMIT);
      editPabblData(pabblData.id, editValues)
        .then(() => {
          // refreshPabblData();
        })
        .catch(err => {
          console.warn('Error while submitting changed values', err);
          setEditMode(MODE_EDIT);
        });
    }
  }

  useEffect(() => {
    if (editMode === MODE_SUBMIT) {
      setEditMode(MODE_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pabblData]);

  useEffect(() => {
    const initialValues = convertDataForEdit(pabblData);
    setHasChangedValues(
      Object.keys(editValues).some(
        key => editValues[key] !== initialValues[key]
      )
    );
  }, [pabblData, editValues]);

  const fieldChange = fieldId => event => {
    const value = event.target.value;
    setEditValues(prevState => ({ ...prevState, [fieldId]: value }));
    if (fieldErrors[fieldId]) {
      setFieldErrors(prevErrors => ({
        ...prevErrors,
        [fieldId]: validateField(fieldId, {
          ...editValues,
          [fieldId]: value
        })
      }));
    }
  };

  const dataValues = editMode ? editValues : pabblData;
  const {
    title = '',
    startTime = '',
    endTime = '',
    length = '',
    quota = '',
    groups = '',
    groupMode = '',
    quotas,
    criteria,
    liveLink = '',
    points = '',
    callBackLink = '',
    status = '',
    id = ''
  } = dataValues;
  function validateForm() {
    const checkFields = ['status', 'startTime', 'endTime'];
    const errors = {};
    checkFields.forEach(fieldId => {
      errors[fieldId] = validateField(fieldId, dataValues);
    });
    setFieldErrors(errors);
    return Object.values(errors).every(err => !err);
  }

  let buttons = [];
  if (id) {
    switch (editMode) {
      case MODE_VIEW:
        buttons = [
          <StyledButton
            key="editButton"
            variant="contained"
            onClick={startEdit}
          >
            Edit
          </StyledButton>
        ];
        break;
      case MODE_EDIT:
        buttons = [
          <StyledButton
            key="submitButton"
            variant="contained"
            onClick={submitEdit}
            disabled={!hasChangedValues}
          >
            Submit
          </StyledButton>,
          <StyledButton
            key="cancelButton"
            variant="contained"
            onClick={cancelEdit}
          >
            Cancel
          </StyledButton>
        ];
        break;
      case MODE_SUBMIT:
        buttons = [<CircularProgress key="loader" />];
        break;
      default:
        buttons = [];
    }
  }

  return (
    <div>
      <StyledTextField
        id="pabbl-id"
        label="id"
        value={id}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <StyledTextField
        id="pabbl-title"
        label="Title"
        value={title}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <StyledTextField
        id="pabbl-length"
        label="length"
        value={length}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <StyledTextField
        id="pabbl-points"
        label="points"
        value={points}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <SurveyField
        fieldId="quota"
        value={quota}
        onChange={fieldChange('quota')}
        fieldError={fieldErrors.quota}
        readonly={!editMode}
        idPrefix="pabbl-"
      />
      <StyledTextField
        id="pabbl-groups"
        label="groups"
        value={groups}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <StyledTextField
        id="pabbl-groupMode"
        label="groupMode"
        value={groupMode}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <NonInputField label="quotas">
        <PreviewJson json={JSON.stringify(quotas, null, 4)} />
      </NonInputField>
      <NonInputField label="criteria">
        <PreviewJson json={JSON.stringify(criteria, null, 4)} />
      </NonInputField>
      <SurveyField
        fieldId="status"
        value={status}
        onChange={fieldChange('status')}
        fieldError={fieldErrors.status}
        readonly={!editMode}
      />
      <SurveyField
        fieldId="startTime"
        value={startTime}
        onChange={fieldChange('startTime')}
        fieldError={fieldErrors.startTime}
        readonly={!editMode}
        idPrefix="pabbl-"
      />
      <SurveyField
        fieldId="endTime"
        value={endTime}
        onChange={fieldChange('endTime')}
        fieldError={fieldErrors.endTime}
        readonly={!editMode}
        idPrefix="pabbl-"
      />
      <StyledTextField
        id="pabbl-liveLink"
        label="liveLink"
        value={liveLink}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <StyledTextField
        id="pabbl-callBackLink"
        label="callBackLink"
        value={callBackLink}
        fullWidth
        InputProps={{
          readOnly: true
        }}
        disabled={!!editMode}
      />
      <ButtonContainer>{buttons}</ButtonContainer>
    </div>
  );
};
SurveyPabblData.propTypes = propTypes;

export default SurveyPabblData;
