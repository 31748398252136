import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MultiSelectWithCreate from '../../input/MultiSelectWithCreate';
// import RadioGroup from '../../input/RadioGroup';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValue: PropTypes.func.isRequired,
  groupModes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  groupMode: PropTypes.string.isRequired,
  setGroupMode: PropTypes.func.isRequired
};
const defaultProps = {
  options: [],
  groupModes: []
};

const ExplanationBlock = styled.div`
  font-size: 80%;
  margin-bottom: 16px;
`;

const Groups = ({
  options,
  value,
  setValue,
  groupModes,
  groupMode,
  setGroupMode
}) => {
  return (
    <div>
      <ExplanationBlock>
        Select an existing group (or groups) to exclude the respondents in that
        group from this survey. Or enter a new group name to be able to exclude
        respondents in future surveys. You can also do both.
      </ExplanationBlock>
      <MultiSelectWithCreate
        options={options}
        id="groups"
        value={value}
        setValue={setValue}
        label="Groups"
      />
      {/* {value && !!value.length && (
        <RadioGroup
          label="Group mode"
          options={groupModes}
          value={groupMode}
          setValue={setGroupMode}
        />
      )} */}
    </div>
  );
};
Groups.propTypes = propTypes;
Groups.defaultProps = defaultProps;
export default Groups;
