import PropTypes from 'prop-types';
import {
  immutableArrayChangeItem,
  immutableObjectRemoveParameter
} from '../../../helpers/helperFunctions';

export const initialState = {
  availableCriteria: [],
  criteriaValues: {},
  quotas: []
};
// action consts

export const SET_AVAILABLE_CRITERIA = 'Set available criteria';
export const ADD_CRITERION = 'Add criterion';
export const SET_CRITERION_VALUE = 'Set criterion value';
export const CLEAR_CRITERION = 'Clear criterion';

export const ADD_QUOTA = 'Add quota';
export const DELETE_QUOTA = 'Delete quota';
export const QUOTA_ADD_CRITERION = 'Quota add criterion';
export const QUOTA_SAVE_ID = 'Quota save title';
export const QUOTA_SAVE_QUOTA = 'Quota save quota';
export const QUOTA_SAVE_CRITERION = 'Quota save quota';
export const QUOTA_SAVE_CRITERION_VALUE = 'Quota save criterion value';
export const SET_QUOTAS = 'Set quotas';

function getInitialValue(type) {
  switch (type) {
    case 'number':
      return '';
    case 'choice':
      return [];
    default:
      return null;
  }
}

const initialQuota = {
  id: '',
  criteria: {},
  quota: ''
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_AVAILABLE_CRITERIA:
      return { ...state, availableCriteria: action.payload };
    case ADD_CRITERION: {
      const { criterionId, type } = action.payload;
      return {
        ...state,
        criteriaValues: {
          ...state.criteriaValues,
          [criterionId]: getInitialValue(type)
        }
      };
    }
    case SET_CRITERION_VALUE: {
      const { criterionId, value } = action.payload;
      if (value === null) {
        return {
          ...state,
          criteriaValues: immutableObjectRemoveParameter(
            state.criteriaValues,
            criterionId
          )
        };
      }
      return {
        ...state,
        criteriaValues: { ...state.criteriaValues, [criterionId]: value }
      };
    }
    case CLEAR_CRITERION: {
      const { criterionId } = action.payload;
      let { type } = action.payload;
      if (!type) {
        const criterium = state.availableCriteria.find(
          crit => crit.key === criterionId
        );
        if (criterium) {
          type = criterium.type;
        }
      }
      return {
        ...state,
        criteriaValues: {
          ...state.criteriaValues,
          [criterionId]: getInitialValue(type)
        }
      };
    }

    case ADD_QUOTA: {
      let counter = 1;
      let initialId;
      const idExists = id => state.quotas.some(quota => quota.id === id);
      do {
        initialId = `quota${counter++}`;
      } while (idExists(initialId));

      counter = 1;
      let reactKey;
      const keyExists = key =>
        state.quotas.some(quota => quota.reactKey === key);
      do {
        reactKey = `key-${counter++}`;
      } while (keyExists(reactKey));

      return {
        ...state,
        quotas: [...state.quotas, { ...initialQuota, id: initialId, reactKey }]
      };
    }

    case DELETE_QUOTA: {
      const { reactKey } = action.payload;
      return {
        ...state,
        quotas: state.quotas.filter(quota => quota.reactKey !== reactKey)
      };
    }

    case QUOTA_ADD_CRITERION: {
      const { reactKey, criterion } = action.payload;
      const quotaIndex = state.quotas.findIndex(
        quota => quota.reactKey === reactKey
      );
      return {
        ...state,
        quotas: immutableArrayChangeItem(state.quotas, quotaIndex, quota => ({
          ...quota,
          criteria: {
            ...quota.criteria,
            [criterion.key]: {
              criterion,
              value: getInitialValue(criterion.type)
            }
          }
        }))
      };
    }
    case QUOTA_SAVE_ID: {
      const { reactKey, value } = action.payload;
      const quotaIndex = state.quotas.findIndex(
        quota => quota.reactKey === reactKey
      );
      return {
        ...state,
        quotas: immutableArrayChangeItem(state.quotas, quotaIndex, quota => ({
          ...quota,
          id: value
        }))
      };
    }
    case QUOTA_SAVE_QUOTA: {
      const { reactKey, value } = action.payload;
      const quotaIndex = state.quotas.findIndex(
        quota => quota.reactKey === reactKey
      );
      return {
        ...state,
        quotas: immutableArrayChangeItem(state.quotas, quotaIndex, quota => ({
          ...quota,
          quota: value
        }))
      };
    }

    case QUOTA_SAVE_CRITERION: {
      const { reactKey } = action.payload;
      const quotaIndex = state.quotas.findIndex(
        quota => quota.reactKey === reactKey
      );
      return {
        ...state,
        quotas: immutableArrayChangeItem(state.quotas, quotaIndex, quota => {
          return {
            ...quota,
            quota: { ...quota, criteria: { ...quota.criteria } }
          };
        })
      };
    }

    case QUOTA_SAVE_CRITERION_VALUE: {
      const { reactKey, criterionKey, value } = action.payload;
      const quotaIndex = state.quotas.findIndex(
        quota => quota.reactKey === reactKey
      );
      return {
        ...state,
        quotas: immutableArrayChangeItem(state.quotas, quotaIndex, quota => ({
          ...quota,
          criteria: {
            ...quota.criteria,
            [criterionKey]: {
              ...quota.criteria[criterionKey],
              value
            }
          }
        }))
      };
    }

    case SET_QUOTAS: {
      const quotas = action.payload;
      let counter = 1;
      return {
        ...state,
        quotas: quotas.map(quota => {
          let reactKey;
          const keyExists = key =>
            state.quotas.some(quota => quota.reactKey === key);
          do {
            reactKey = `key-${counter++}`;
          } while (keyExists(reactKey));

          return {
            ...quota,
            reactKey
          };
        })
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};

/**
 * Criteria store:
 * {
 *      availableCritera: Array with the available criteria fetch from Pabbl api,
 *      criteriaValues: Object with the values selected by the user
 *          key: Criterion key (id || question)
 *          value: depends on criterion type:
 *              - number: String with selected ranges (ie. "18-25, 55-65")
 *              - choice: Array with selected answer codes
 * }
 */
const availableCriterionType = PropTypes.shape({
  id: PropTypes.string, // NB is not always present!!
  key: PropTypes.string, // Because id is not always present, we create our own key instead
  question: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['number', 'choice']).isRequired
});
const criterionValueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.number)
]);

export const quotumPropTypes = PropTypes.shape({
  reactKey: PropTypes.string,
  id: PropTypes.string,
  criteria: PropTypes.objectOf(
    PropTypes.shape({
      criterion: availableCriterionType,
      value: criterionValueType
    })
  ),
  quota: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) // number or number string
});

export const propTypes = {
  availableCriteria: PropTypes.arrayOf(availableCriterionType),
  criteriaValues: PropTypes.objectOf(criterionValueType),
  quotas: PropTypes.arrayOf(quotumPropTypes)
};
