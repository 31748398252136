import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress } from '@material-ui/core';
import { StyledTextField } from './SurveyFields';

const ButtonContainer = styled.div`
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  margin-right: 16px;
`;

const StyledTextFieldS = styled(StyledTextField)`
  width: 60px;
`;

const formatQuotaValue = value => (value >= 1000000000 ? <>&infin;</> : value);

const MODE_VIEW = 0;
const MODE_EDIT = 1;
const MODE_SUBMIT = 2;

const QuotasMonitor = ({
  initialQuotas,
  currentQuotas,
  decipherQuotas,
  saveQuotas
}) => {
  const [editMode, setEditMode] = useState(MODE_VIEW);
  const [editValues, setEditValues] = useState([]);
  const [hasChangedValues, setHasChangedValues] = useState(false);

  function startEdit() {
    setEditValues(currentQuotas);
    setHasChangedValues(false);
    setEditMode(MODE_EDIT);
  }

  function cancelEdit() {
    setEditMode(MODE_VIEW);
  }

  function validateForm() {
    return true;
  }

  async function submitEdit() {
    if (validateForm()) {
      setEditMode(MODE_SUBMIT);
      saveQuotas(editValues)
        .then(() => {
          // refreshPabblData();
        })
        .catch(err => {
          console.warn('Error while submitting changed values', err);
          setEditMode(MODE_EDIT);
        });
    }
  }

  // reset editMode after data has been reloaded
  useEffect(() => {
    if (editMode === MODE_SUBMIT) {
      setEditMode(MODE_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuotas]);

  useEffect(() => {
    setHasChangedValues(
      editValues.some(
        (quotaValue, index) => quotaValue !== currentQuotas[index]
      )
    );
  }, [currentQuotas, editValues]);

  const fieldChange = index => event => {
    const value = parseInt(event.target.value, 10);
    setEditValues(prevState => [
      ...prevState.slice(0, index),
      { ...prevState[index], quota: value },
      ...prevState.slice(index + 1)
    ]);
    // if (fieldErrors[fieldId]) {
    //   setFieldErrors(prevErrors => ({
    //     ...prevErrors,
    //     [fieldId]: validateField(fieldId, {
    //       ...editValues,
    //       [fieldId]: value
    //     })
    //   }));
    // }
  };

  let buttons = [];
  if (currentQuotas && currentQuotas.length) {
    switch (editMode) {
      case MODE_VIEW:
        buttons = [
          <StyledButton
            key="editButton"
            variant="contained"
            onClick={startEdit}
          >
            Edit
          </StyledButton>
        ];
        break;
      case MODE_EDIT:
        buttons = [
          <StyledButton
            key="submitButton"
            variant="contained"
            onClick={submitEdit}
            disabled={!hasChangedValues}
          >
            Submit
          </StyledButton>,
          <StyledButton
            key="cancelButton"
            variant="contained"
            onClick={cancelEdit}
          >
            Cancel
          </StyledButton>
        ];
        break;
      case MODE_SUBMIT:
        buttons = [<CircularProgress key="loader" />];
        break;
      default:
        buttons = [];
    }
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="right">initial</TableCell>
              <TableCell align="right">pabbl</TableCell>
              <TableCell align="right">decipher</TableCell>
              {editMode === MODE_VIEW ? null : (
                <TableCell align="right">new quota</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentQuotas.map((quota, index) => {
              const decipherQuotaData = decipherQuotas.find(
                dq => dq.id === quota.id
              );
              const decipherQuotaValues = decipherQuotaData ? (
                <>
                  {formatQuotaValue(decipherQuotaData.limit)}-
                  {formatQuotaValue(decipherQuotaData.complete)}=
                  {decipherQuotaData.limit >= 1000000000 ? (
                    <>&infin;</>
                  ) : (
                    formatQuotaValue(
                      decipherQuotaData.limit - decipherQuotaData.complete
                    )
                  )}
                </>
              ) : (
                ''
              );
              const initialQuotaData = initialQuotas.find(
                iq => iq.id === quota.id
              );

              return (
                <TableRow key={quota.id}>
                  <TableCell component="th" scope="row">
                    {quota.id}
                  </TableCell>
                  <TableCell align="right">
                    {initialQuotaData ? initialQuotaData.quota : ''}
                  </TableCell>
                  <TableCell align="right">{quota.quota}</TableCell>
                  <TableCell align="right">{decipherQuotaValues}</TableCell>
                  {editMode === MODE_VIEW ? null : (
                    <TableCell align="right">
                      <StyledTextFieldS
                        id={`quotas-${index}`}
                        value={editValues[index].quota}
                        onChange={fieldChange(index)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ButtonContainer>{buttons}</ButtonContainer>
    </div>
  );
};

export default QuotasMonitor;
