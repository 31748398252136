import PropTypes from 'prop-types';

const surveyProps = {
  surveyId: PropTypes.string,
  decipherId: PropTypes.string,
  projectId: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quota: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  liveLink: PropTypes.string,
  targets: PropTypes.arrayOf(PropTypes.string)
};

/*
{
    "id":			"<unique survey id>string(63)",
    "title":		"<survey title>string(255)",
    "status":		"<status code>string(31)",			# "standby","test","live","suspended","terminated"
    "groups":		["<group id>string(63)"],				# Optional grouping of surveys – targets will be offered only one survey in a group
    "startTime":	"<start time>ISO 8601",				# Optional start time for this survey
    "endTime":		"<end time>ISO 8601",					# Optional end time for this survey	
    "length":		<estimated length in minutes>,
    "quota":		<currently available>,				# Total number of surveys available for Pabbl
    "testLink":	"<rest survey link>string(1023)",		# Optional test link for the survey
    "liveLink":	"<live survey link>string(1023)",
    "criteria": 	[										# Optional criteria for this survey
        {
            "id":			"<selection criterion id>string(63)",		# Criteria ID and codes can be obtained with the criteria API
            "codes":		[<code>]						# Answer-code; comma-delimited string is also allowed
        }
    ],
    "quotas": [												 # Optional quota for this survey
        {
            "id":			<quota id>,
            "quota":		<value>,						# Available quota for targets matching ALL quota criteria
            "criteria": 	[
                {
                    "id":			"<selection criterion id>string(63)",	# Criteria ID and codes can be obtained with the criteria API
                    "codes":		[<code>]				# Answer-code; comma-delimited string is also allowed
                }
            ]
        }
    ],
    "targets":		["<advertising id>"string(63)]			# Optional list of targets for this survey (max 10000)
}

*/

export { surveyProps };
