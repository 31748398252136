import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MainContainer = styled.div`
  display: flex;
`;

// function mod(n, m) {
//   return ((n % m) + m) % m;
// }

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const MonthFilters = ({ id, value, onChange }) => {
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    const today = new Date();
    // const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    // const month = mod(currentMonth - 2, 12);
    // const year = currentYear + Math.floor((currentMonth - 2) / 12);
    setYearOptions(Array.from(new Array(20), (x, i) => currentYear - i));
    // onChange({ year, month });
  }, []);

  return (
    <MainContainer>
      <Autocomplete
        disableClearable
        value={value.month}
        onChange={(event, newValue) => {
          onChange({ year: value.year, month: newValue });
        }}
        id={`${id || 'select-box'}-month`}
        options={Array.from(new Array(12), (x, i) => i)}
        getOptionLabel={option => (option ? months[option] : '')}
        style={{ width: 180 }}
        renderInput={params => (
          <TextField {...params} label="month" variant="outlined" fullWidth />
        )}
      />
      <Autocomplete
        disableClearable
        value={value.year}
        onChange={(event, newValue) => {
          onChange({ year: newValue, month: value.month });
        }}
        id={`${id || 'select-box'}-year`}
        options={yearOptions}
        getOptionLabel={option => option.toString()}
        style={{ width: 120 }}
        renderInput={params => (
          <TextField {...params} label="year" variant="outlined" fullWidth />
        )}
      />
    </MainContainer>
  );
};
export default MonthFilters;

export const SelectedMonth = ({ value }) => {
  if (!value) return null;
  let month = '';
  if (typeof month !== 'undefined') {
    month = months[value.month];
  }
  const spacer = month && value.year ? ' ' : '';
  return <h2>{`${month}${spacer}${value.year}`}</h2>;
};
