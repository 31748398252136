import axios from 'axios';
const FileDownload = require('js-file-download');

const api = axios.create({ baseURL: `/api/m2m/v1/` });

export function convertVideo(stream, token) {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('stream', stream, 'recordedstream.webm');

    api
      .post('convert', data, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      })
      .then(res => {
        if (res && res.data) {
          FileDownload(res.data, 'm2m.mp4');
          resolve();
        } else {
          console.log('res.data:', res.data);
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.message ? `[${res.message}]` : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

// export function test(token) {
//   return new Promise((resolve, reject) => {
//     api
//       .get('test', {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       })
//       .then(res => {
//         if (res && res.data) {
//           console.log('res.data:', res.data);
//           resolve(res.data);
//         } else {
//           console.log('res.data:', res.data);
//           reject(
//             new Error(
//               'Unexpected result from api.' +
//                 (res && res.message ? `[${res.message}]` : '')
//             )
//           );
//         }
//       })
//       .catch(err => reject(err));
//   });
// }
