import React from 'react';
// import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';

import PrivateRoute from '../../../auth/PrivateRoute';

import ListIcon from '@material-ui/icons/List';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import TableChartIcon from '@material-ui/icons/TableChart';

import MainLayout from '../../layout/MainLayout';
// import CreateSurvey from '../components/tools/Pabbl/CreateSurvey';
import SurveyView from './SurveyView';
import Statistics from './Statistics';
import SurveyLinkCallback from './callback/SurveyLinkCallback';
import ExitSurveyCallback from './callback/ExitSurveyCallback';

const propTypes = {};
const defaultProps = {};

const menuItems = [
  {
    label: 'Survey list',
    to: '/pabbl/surveys',
    icon: <ListIcon />
  },
  {
    label: 'Add survey',
    to: '/pabbl/surveys/add',
    icon: <LibraryAddOutlinedIcon />
  },
  {
    label: 'Statistics',
    to: '/pabbl/statistics',
    icon: <TableChartIcon />
  }
];

const Layout = props => (
  <MainLayout title="Pabbl tool" menuItems={menuItems} {...props} />
);

const Pabbl = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        {/* callbacks: */}
        <Route
          key="surveylink-callback"
          path={`${path}/surveylink-callback`}
          render={props => {
            let queryParams = {};
            if (props && props.location && props.location.search) {
              queryParams = queryString.parse(props.location.search);
            }
            return <SurveyLinkCallback {...queryParams} />;
          }}
        />
        <Route
          key="exitsurvey-callback"
          path={`${path}/exitsurvey-callback`}
          render={props => {
            let queryParams = {};
            if (props && props.location && props.location.search) {
              queryParams = queryString.parse(props.location.search);
            }
            return <ExitSurveyCallback {...queryParams} />;
          }}
        />
        {/* tool routes */}
        <PrivateRoute
          key="surveys"
          path={`${path}/surveys/:view?/:survey?`}
          render={props => {
            const { match, history } = props;
            const { params } = match;
            return (
              <Layout>
                <SurveyView
                  routeTo={relativePath =>
                    history.push(`${path}/surveys/${relativePath}`)
                  }
                  {...params}
                />
              </Layout>
            );
          }}
        />
        <PrivateRoute
          key="statistics"
          path={`${path}/statistics/:survey?`}
          render={props => {
            const { match } = props;
            const { params } = match;
            return (
              <Layout>
                <Statistics {...params} />
              </Layout>
            );
          }}
        />
        {/* home / fallback */}
        <PrivateRoute
          path={path}
          render={() => {
            return (
              <Layout>
                <h1>Pabbl tool</h1>
                <p>
                  You can use this tool to send a survey to the Pabbl api and to
                  monitor and manage it.
                </p>
                <p>
                  Choose Add survey in the menu to the left to get started with
                  a new survey or go to the survey list to see currently active
                  surveys.
                </p>
              </Layout>
            );
          }}
        ></PrivateRoute>
      </Switch>
    </div>
  );
};
Pabbl.propTypes = propTypes;
Pabbl.defaultProps = defaultProps;

export default Pabbl;
