import { createMuiTheme } from '@material-ui/core/styles';
import { dvjRed, dvjBlue } from './colors';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: dvjRed
    },
    secondary: {
      main: dvjBlue
    }
  }
});

export default theme;
