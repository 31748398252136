import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputAdornment, Button, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useAuth0 } from '../../../auth/react-auth0-spa';
import { fetchCriteriaOptions } from '../../../models/pabbl/criteria';
import { fetchDecipherQuotas } from '../../../models/pabbl/quotas';
import { fetchGroups } from '../../../models/pabbl/survey';
import * as criteriaStore from './SurveyStateManager';
import Criteria from './Criteria';
import QuotasContainer from './QuotasContainer';
import { StyledTextField } from './SurveyFields';
import Targets from './Targets';
import Groups from './Groups';
import UrlParameters from './UrlParameters';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  /**
   * function onSubmit(surveyValues)
   *    surveyValues: see {surveyProps} in ./types
   */
  onSubmit: PropTypes.func.isRequired
};
const defaultProps = {};

const StyledContainer = styled(Paper)`
  max-width: 800px;
`;

const StyledForm = styled.form`
  /* max-width: 400px; */
  padding: 16px;
`;

const FormSection = styled(props => <Paper variant="outlined" {...props} />)`
  padding: 8px;
  margin-bottom: 16px;
`;

const FormSectionLabel = styled.h4`
  margin-top: 0;
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
`;

const groupModes = [
  {
    value: 'community',
    label: 'community'
  },
  {
    value: 'exclusive',
    label: 'exclusive'
  }
];

const initialValues = {
  decipherId: '',
  // surveyId: '',
  projectId: '',
  title: '',
  status: 'test',
  length: '',
  quota: '',
  startDate: new Date().toISOString().slice(0, 10),
  endDate: '',
  liveLink: '',
  targets: null,
  groups: [],
  groupMode: 'exclusive',
  urlParameters: []
};
const CreateSurvey = ({ onCancel, onSubmit }) => {
  const [values, setValues] = useState(initialValues);
  const [fieldErrors, setFieldErrors] = useState({});
  // {type: ["error"|"warning"|"info"|"success"], text: string}
  const [submitMessage, setSubmitMessage] = useState(null);
  const [criteriaState, criteriaDispatch] = useReducer(
    criteriaStore.reducer,
    criteriaStore.initialState
  );
  const [groupOptions, setgroupOptions] = useState([]);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;
    async function fetchCriteria() {
      const token = await getTokenSilently();
      fetchCriteriaOptions(token).then(fetchResult => {
        if (isMounted) {
          criteriaDispatch({
            type: criteriaStore.SET_AVAILABLE_CRITERIA,
            payload: fetchResult.map(criterion => ({
              ...criterion,
              key: criterion.id || criterion.question
            }))
          });
        }
      });
    }

    async function fetchgroupOptions() {
      const token = await getTokenSilently();
      fetchGroups(token).then(fetchResult => {
        if (isMounted) {
          setgroupOptions(fetchResult);
        }
      });
    }

    fetchCriteria();
    fetchgroupOptions();
    return () => {
      isMounted = false;
    };
  }, [getTokenSilently]);

  const setSingleValue = (fieldId, value) => {
    setValues(prevState => ({ ...prevState, [fieldId]: value }));
    if (fieldErrors[fieldId]) {
      setFieldErrors(prevErrors => ({
        ...prevErrors,
        [fieldId]: validateField(fieldId, { ...values, [fieldId]: value })
      }));
    }
  };

  const fieldChange = fieldId => event => {
    const value = event.target.value;
    setSingleValue(fieldId, value);
  };

  const validateSingleQuotas = quotasValue => {
    if (!quotasValue.id) {
      return 'id has no value';
    }
    if (typeof quotasValue.quota === 'undefined' || quotasValue.quota === '') {
      return 'quota has no value';
    }
    if (
      !quotasValue.criteria ||
      Object.keys(quotasValue.criteria).length === 0
    ) {
      return 'must contain at least one criterium';
    }
    if (
      Object.values(quotasValue.criteria).some(
        criteriumValue => criteriumValue === '' || criteriumValue === []
      )
    ) {
      return 'all criteria must contain values';
    }
  };

  const validateField = (fieldId, validateValues) => {
    let valuesToValidate;
    if (!validateValues) {
      valuesToValidate = values;
    } else {
      valuesToValidate = validateValues;
    }
    switch (fieldId) {
      case 'endDate':
        if (!valuesToValidate.endDate) {
          return 'This field can not be empty!';
        } else if (
          valuesToValidate.startDate &&
          valuesToValidate.endDate < valuesToValidate.startDate
        ) {
          return 'End date must be greater than start date';
        }
        return '';
      case 'quotas': {
        // it's fine to have no quotas, but if there are, they must be valid
        if (!valuesToValidate.quotas || valuesToValidate.quotas.length === 0) {
          return '';
        }
        let error = '';
        for (var i = 0; i < valuesToValidate.quotas.length; i++) {
          const singleQuotas = valuesToValidate.quotas[i];
          const validateSingleQuotasResult = validateSingleQuotas(singleQuotas);
          if (validateSingleQuotasResult) {
            error = `Quota ${i +
              1} is not valid: ${validateSingleQuotasResult}`;
            break;
          }
        }
        return error;
      }
      case 'urlParameters':
        if (
          valuesToValidate.urlParameters.some(
            param => param.key === '' && param.value !== ''
          )
        ) {
          return 'All url parameters must have a key!';
        }
        return '';
      default:
        if (valuesToValidate[fieldId]) {
          return '';
        } else {
          return 'This field can not be empty!';
        }
    }
  };

  function validateForm() {
    const checkFields = [
      'decipherId',
      'projectId',
      'title',
      'status',
      'length',
      'startDate',
      'endDate',
      'urlParameters'
    ];
    const errors = {};
    checkFields.forEach(fieldId => {
      errors[fieldId] = validateField(fieldId);
    });
    errors.quotas = validateField('quotas', criteriaState);
    setFieldErrors(errors);
    return Object.values(errors).every(err => !err);
  }

  function handleSubmit() {
    if (validateForm()) {
      setSubmitMessage(null);
      onSubmit({
        general: values,
        criteria: criteriaState.criteriaValues,
        quotas: criteriaState.quotas
      });
    } else {
      setSubmitMessage({
        type: 'error',
        text: `There are errors in your form, please check all data.`
      });
    }
  }

  const convertCriteria = backEndCriteria => {
    const criteria = {};
    let genderCode;
    if (backEndCriteria.gender) {
      if (backEndCriteria.gender === 'M') {
        // assume 1 is always male in Pabbl gender criteria
        genderCode = 1;
      } else if (backEndCriteria.gender === 'F') {
        // assume 2 is always female in Pabbl gender criteria for gender
        genderCode = 2;
      }
      // criteria.push({ id: 'gender', codes: genderCode });
      const criterion = criteriaState.availableCriteria.find(
        criterion => criterion.key === 'gender'
      );
      criteria.gender = { criterion, value: [genderCode] };
    }
    if (backEndCriteria.age) {
      // criteria.push({ id: 'age', codes: backEndCriteria.age });
      const criterion = criteriaState.availableCriteria.find(
        criterion => criterion.key === 'age'
      );
      criteria.age = { criterion, value: backEndCriteria.age };
    }
    return criteria;
  };

  async function handleBlurDecipherId() {
    if (values.decipherId) {
      const token = await getTokenSilently();
      const fetchedQuotas = await fetchDecipherQuotas(values.decipherId, token);

      if (fetchedQuotas) {
        criteriaDispatch({
          type: criteriaStore.SET_QUOTAS,
          payload: fetchedQuotas.map(quota => {
            return { ...quota, criteria: convertCriteria(quota.criteria) };
          })
        });
      }
    }
  }

  let firstError = '';
  if (
    fieldErrors &&
    Object.keys(fieldErrors).filter(key => fieldErrors[key]).length
  ) {
    const errorField = Object.keys(fieldErrors).filter(
      key => fieldErrors[key]
    )[0];
    firstError = `${errorField}: ${fieldErrors[errorField]}`;
  }

  return (
    <StyledContainer>
      <StyledForm autoComplete="off">
        <h3>Create survey</h3>
        <FormSection>
          <FormSectionLabel>General</FormSectionLabel>
          <StyledTextField
            id="decipherId"
            label="Survey id (Decipher)"
            value={values.decipherId}
            onChange={fieldChange('decipherId')}
            onBlur={handleBlurDecipherId}
            fullWidth
            error={!!fieldErrors.decipherId}
            helperText={
              fieldErrors.decipherId ||
              "Fill in the survey id from Decipher, ie '200215'"
            }
          />
          <StyledTextField
            id="projectId"
            label="Project id (Kinesis Panel)"
            value={values.projectId}
            onChange={fieldChange('projectId')}
            fullWidth
            error={!!fieldErrors.projectId}
            helperText={
              fieldErrors.projectId ||
              "Fill in the project ID from Kinesis Panel, ie '2733'"
            }
          />
          <StyledTextField
            id="title"
            label="Title"
            value={values.title}
            onChange={fieldChange('title')}
            fullWidth
            error={!!fieldErrors.title}
            helperText={fieldErrors.title}
          />
          {/* <SurveyField
            fieldId="status"
            value={values.status}
            onChange={fieldChange('status')}
            fieldError={fieldErrors.status}
          /> */}
          <StyledTextField
            id="status"
            label="Status"
            value={values.status}
            fullWidth
            InputProps={{
              readOnly: true
            }}
            helperText="You can only change the status after creation and checking if everything is right."
          />
          <StyledTextField
            id="length"
            label="Survey length"
            value={values.length}
            type="number"
            onChange={fieldChange('length')}
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>
            }}
            fullWidth
            error={!!fieldErrors.length}
            helperText={fieldErrors.length}
          />
          <StyledTextField
            id="overall-quota"
            label="Quota (overall)"
            value={values.quota}
            type="number"
            onChange={fieldChange('quota')}
            fullWidth
            error={!!fieldErrors.quota}
            helperText={fieldErrors.quota}
          />
          <StyledTextField
            id="startDate"
            label="Date from"
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            value={values.startDate}
            onChange={fieldChange('startDate')}
            fullWidth
            error={!!fieldErrors.startDate}
            helperText={fieldErrors.startDate}
          />
          <StyledTextField
            id="endDate"
            label="Date to"
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            value={values.endDate}
            onChange={fieldChange('endDate')}
            fullWidth
            error={!!fieldErrors.endDate}
            helperText={fieldErrors.endDate}
          />
          {/* <StyledTextField
            id="liveLink"
            label="url"
            value={values.liveLink}
            onChange={fieldChange('liveLink')}
            fullWidth
            error={!!fieldErrors.liveLink}
            helperText={fieldErrors.liveLink}
          /> */}
        </FormSection>

        <FormSection>
          <FormSectionLabel>Url parameters</FormSectionLabel>
          <UrlParameters
            parameters={values.urlParameters}
            setParameters={newParameters => {
              setSingleValue('urlParameters', newParameters);
            }}
            errorText={fieldErrors.urlParameters}
          />
        </FormSection>

        <FormSection>
          <FormSectionLabel>Criteria</FormSectionLabel>
          <Criteria
            criteria={criteriaState.availableCriteria}
            state={criteriaState.criteriaValues}
            setState={(criterionId, value) => {
              if (value === null) {
                criteriaDispatch({
                  type: criteriaStore.CLEAR_CRITERION,
                  payload: { criterionId }
                });
              }
              criteriaDispatch({
                type: criteriaStore.SET_CRITERION_VALUE,
                payload: { criterionId, value }
              });
            }}
            // addCriterion={(criterionId, type) => {
            //   criteriaDispatch({
            //     type: criteriaStore.ADD_CRITERION,
            //     payload: { criterionId, type }
            //   });
            // }}
            // clearCriterion={(criterionId, type) => {
            //   criteriaDispatch({
            //     type: criteriaStore.CLEAR_CRITERION,
            //     payload: { criterionId, type }
            //   });
            // }}
          />
        </FormSection>
        <FormSection>
          <FormSectionLabel>
            Groups (for projects with multiple measurements, i.e. tracker or
            campaign evaluation)
          </FormSectionLabel>
          <Groups
            options={groupOptions}
            value={values.groups}
            setValue={newGroups => setSingleValue('groups', newGroups)}
            groupModes={groupModes}
            groupMode={values.groupMode}
            setGroupMode={newGroupMode =>
              setSingleValue('groupMode', newGroupMode)
            }
          />
        </FormSection>
        <FormSection>
          <FormSectionLabel>Quota's</FormSectionLabel>
          <QuotasContainer
            availableCriteria={criteriaState.availableCriteria}
            dispatch={criteriaDispatch}
            quotas={criteriaState.quotas}
          />
        </FormSection>
        <FormSection>
          <FormSectionLabel>Targets</FormSectionLabel>
          <Targets
            targets={values.targets}
            setTargets={value => setSingleValue('targets', value)}
          />
        </FormSection>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          Submit
        </StyledButton>
        <StyledButton onClick={() => onCancel()}>Cancel</StyledButton>
        {submitMessage ? (
          <Alert
            severity={submitMessage.type}
            onClose={() => setSubmitMessage(null)}
          >
            {submitMessage.text} <br />
            {firstError}
          </Alert>
        ) : null}
      </StyledForm>
    </StyledContainer>
  );
};
CreateSurvey.propTypes = propTypes;
CreateSurvey.defaultProps = defaultProps;
export default CreateSurvey;
