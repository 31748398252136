import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { useAuth0 } from './auth/react-auth0-spa';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import history from './helpers/history';
import PrivateRoute from './auth/PrivateRoute';

import muiTheme from './config/style/muiTheme';

import Home from './components/Home';
import Pabbl from './components/tools/Pabbl';
import PanelData from './components/tools/PanelData';
import Moment2Moment from './components/tools/Moment2Moment';

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <PrivateRoute path="/m2m" component={Moment2Moment} />
            <Route path="/pabbl" component={Pabbl} />
            <Route path="/paneldata" component={PanelData} />
            <Route path="/" component={Home} />
          </Switch>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
