export function immutableArrayChangeItem(arr, itemIndex, itemEditFunc) {
  return [
    ...arr.slice(0, itemIndex),
    itemEditFunc(arr[itemIndex]),
    ...arr.slice(itemIndex + 1)
  ];
}

export function immutableArrayRemoveItem(arr, itemIndex) {
  return [...arr.slice(0, itemIndex), ...arr.slice(itemIndex + 1)];
}

export function immutableObjectRemoveParameter(obj, parameter) {
  const { [parameter]: remove, ...newObj } = obj;
  return newObj;
}

export function capitalizeFirst(string) {
  if (!string) return string;
  return string[0].toUpperCase() + string.slice(1);
}

// attempt to convert any strin to a string that can be used as html id
// might need improvement
export function stringToDomId(string) {
  return string.replace(/^[^a-zA-Z]+|[^\w:.-]+/g, '');
}
