import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';

const Container = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

/**
 * graphType = 'engagement' | 'moment-to-moment'
 */
const M2M = React.forwardRef(
  (
    {
      data = [],
      percentWatch,
      graphType = 'moment-to-moment',
      yMin = 0,
      yMax = 100,
      width = 500,
      height = 500
    },
    chartRef
  ) => {
    const baseLine = data.map(() => 50);

    const fillColor = type => {
      switch (type) {
        case 'engagement':
          return '#E5E5E5';
        case 'moment-to-moment':
          return 'rgba(255,255,255,0)';
        default:
          return 'rgba(255,255,255,0)';
      }
    };

    const chartData = {
      labels: data.map((val, i) => i),
      datasets: [
        {
          label: 'm2m',
          backgroundColor: fillColor(graphType),
          borderColor: '#2161F7',
          pointRadius: 0,
          lineTension: 0.25,
          borderWidth: 12,
          data: data
        }
      ]
    };

    if (graphType === 'moment-to-moment') {
      chartData.datasets.push({
        label: 'baseline',
        backgroundColor: fillColor(),
        borderColor: '#AAAAAA',
        pointRadius: 0,
        borderWidth: 3,
        data: baseLine
      });
    }

    return (
      <Container width={width} height={height}>
        <Line
          ref={chartRef}
          width={width}
          height={height}
          data={chartData}
          options={{
            percentWatch: percentWatch,
            scaleShowGridLines: true,
            scaleShowHorizontalLines: true,
            scaleShowVerticalLines: true,
            title: {
              display: false
            },
            legend: {
              display: false
            },

            scales: {
              xAxes: [
                {
                  afterFit: scaleInstance => {
                    scaleInstance.width = 100; // sets the width to 100px
                  },
                  type: 'category',
                  left: 500,
                  gridLines: {
                    drawOnChartArea: false
                  },
                  ticks: {
                    min: 0,
                    maxRotation: 0,
                    callback: (value, index) => {
                      if (index === data.length - 1 && data.length > 10) {
                        const val =
                          Math.round(value) % 5 === 0 ? Math.round(value) : '';
                        return val;
                      } else if (
                        index === data.length - 1 &&
                        data.length <= 10
                      ) {
                        const val = Math.round(value);
                        return val;
                      } else if (data.length < 10) {
                        return value;
                      } else {
                        const val = value % 5 === 0 ? value : '';
                        return val;
                      }
                    }
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    drawOnChartArea: false
                  },
                  ticks: {
                    min: yMin,
                    max: yMax
                  },
                  afterFit: scale => {
                    scale.width = 33; // set value as you wish
                  }
                }
              ]
            }
          }}
          plugins={[
            {
              drawLine: function(chartInstance, pct) {
                // console.log('lineScroller');
                var y = chartInstance.scales['y-axis-0'];
                var x = chartInstance.scales['x-axis-0'];
                var context = chartInstance.chart.ctx;
                var lineLeftOffset = ((x.right - x.left) / 100) * pct + x.left;

                // render vertical line
                context.beginPath();
                context.strokeStyle = '#aaa'; // Line color
                context.lineWidth = 3; // Line thickness
                context.moveTo(lineLeftOffset, y.top);
                context.lineTo(lineLeftOffset, y.bottom);
                context.stroke();
              },

              afterDatasetsDraw: function(chart, easing) {
                this.drawLine(chart, chart.options.percentWatch);
              }
            }
          ]}
        />
      </Container>
    );
  }
);

export default M2M;
