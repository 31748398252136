import axios from 'axios';

const api = axios.create({ baseURL: `/api/pabbl/v1/` });

export function fetchStatistics(year, month, token) {
  return new Promise((resolve, reject) => {
    api
      .get(`/statistics/${year}/${month}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res.data) {
          resolve(res.data.data);
        } else {
          console.log(res);
          reject(new Error('Invalid result'));
        }
      })
      .catch(err => reject(err));
  });
}
