import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

export const answerCodePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);

export const answerPropTypes = PropTypes.shape({
  code: answerCodePropType.isRequired,
  text: PropTypes.string.isRequired
});

const criterionPropTypes = {
  question: PropTypes.string.isRequired, // The question text ie "What is your age?"
  id: PropTypes.string.isRequired, // Criterion identifier from Pabbl api
  type: PropTypes.oneOf(['number', 'choice']).isRequired,
  answers: PropTypes.arrayOf(answerPropTypes),
  value: PropTypes.oneOfType([
    answerCodePropType,
    PropTypes.arrayOf(answerCodePropType)
  ]),
  setValue: PropTypes.func.isRequired
};
const defaultProps = {
  answers: [],
  value: undefined
};

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 0.8rem;
    line-height: 1;
  }
  & .MuiCheckbox-root {
    padding: 1px;
    margin-left: 8px;
  }
`;

const CriterionContainer = styled.div`
  padding: 0 8px;
`;

const InputContainer = styled.div`
  padding-left: 16px;
`;

export const CriterionInput = ({ id, type, answers, value, setValue }) => {
  switch (type) {
    case 'choice':
      return (
        <MultipleChoiceCriterion
          answers={answers}
          value={value}
          setValue={setValue}
        />
      );
    case 'number':
      if (id === 'age') {
        return <AgeCriterion value={value} setValue={setValue} />;
      } else {
        // NB: Currently there are no known 'number' criteria other than 'age'
        return <NumberCriterion value={value} setValue={setValue} />;
      }
    default:
      return <div>Unsupported criteria type ({type})</div>;
  }
};
CriterionInput.propTypes = {
  id: criterionPropTypes.id,
  type: criterionPropTypes.type,
  answers: criterionPropTypes.answers,
  value: criterionPropTypes.value,
  setValue: criterionPropTypes.setValue
};

const MultipleChoiceCriterion = ({ answers, value, setValue }) => {
  const changeHandler = code => event => {
    const { checked } = event.target;
    if (checked) {
      // add
      if (value.indexOf(code) === -1) {
        setValue([...value, code]);
      }
    } else {
      // remove
      setValue(value.filter(val => val !== code));
    }
  };
  return (
    <FormControl component="fieldset">
      {/* <FormLabel component="legend">Choices</FormLabel> */}
      <FormGroup>
        {answers.map(answer => {
          return (
            <StyledFormControlLabel
              key={answer.code}
              control={
                <Checkbox
                  size="small"
                  checked={value.indexOf(answer.code) > -1}
                  color="default"
                  onChange={changeHandler(answer.code)}
                  value={answer.code}
                />
              }
              label={answer.text}
            />
          );
        })}
      </FormGroup>
      {/* <FormHelperText>Select criteria</FormHelperText> */}
    </FormControl>
  );
};
MultipleChoiceCriterion.propTypes = {
  answers: criterionPropTypes.answers,
  value: PropTypes.arrayOf(answerCodePropType),
  setValue: criterionPropTypes.setValue
};
MultipleChoiceCriterion.defaultProps = { value: [] };

const AgeCriterion = ({ label, defaultValue, value, setValue }) => {
  const handleChange = event => {
    const value = event.target.value;
    setValue(value.replace('+', '-99'));
  };
  return (
    <TextField
      label={label}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      helperText="Example: '21' or '18-35' or '18-25, 65-75'"
      margin="dense"
    />
  );
};
AgeCriterion.propTypes = {
  label: PropTypes.string,
  defaultValue: criterionPropTypes.value,
  value: answerCodePropType,
  setValue: criterionPropTypes.setValue
};
AgeCriterion.defaultProps = { label: '', value: '' };

const NumberCriterion = ({ label, defaultValue, value, setValue }) => {
  const handleChange = event => {
    const value = event.target.value;
    setValue(value);
  };
  return (
    <TextField
      label={label}
      // id={id}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      // helperText="Example: '21' or '18-35' or '18-25, 65-75'"
      margin="dense"
    />
  );
};
NumberCriterion.propTypes = {
  label: PropTypes.string,
  defaultValue: criterionPropTypes.value,
  value: criterionPropTypes.value,
  setValue: criterionPropTypes.setValue
};
NumberCriterion.defaultProps = { label: '', value: '' };

const Criterion = ({ question, id, type, answers, value, setValue }) => {
  const [activated, setActivated] = useState(!!(value && value.length > 0));
  const handleSwitch = event => {
    const { checked } = event.target;
    if (checked) {
      setActivated(true);
    } else {
      setActivated(false);
      setValue(null);
    }
  };

  return (
    <CriterionContainer>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={activated}
              onChange={handleSwitch}
              color="primary"
            />
          }
          label={question}
        />
      </div>
      {activated ? (
        <InputContainer>
          <CriterionInput
            id={id}
            type={type}
            answers={answers}
            value={value}
            setValue={setValue}
          />
        </InputContainer>
      ) : null}
    </CriterionContainer>
  );
};
Criterion.propTypes = criterionPropTypes;
Criterion.defaultProps = defaultProps;
export default Criterion;
