import axios from 'axios';
const api = axios.create({ baseURL: `/api/pabbl/v1/` });

/**
 * Function to call when a respondent enters a survey
 * The url for the SurveyLinkCallback will be used as 'liveLink' in Pabbl
 * This means that when a Pabbl user chooses to fill in a survey, (s)he will be
 * redirected to our callback, which will call this function. The backend will check
 * if this Pabbl user is known in Panel already (by the referralKey). If not a new panelist
 * will be created. The backend returns a link to the survey with all required parameters.
 * This function returns a Promise with that survey link and a sessionId as resolve value
 *  {surveyLink, sessionId}.
 *
 * @param {string} surveyId - The unique surveyId under which the survey is sent to Pabbl
 * @param {string} referralKey - The user (respondent) id from Pabbl
 * @param {object} restQuery - Other query params added to the url by Pabbl, like:
 * @param {string} restQuery.programId
 * @param {string} restQuery.signature
 * @param {string|number} restQuery.age
 */
function enterSurvey(surveyId, referralKey, restQuery) {
  return new Promise((resolve, reject) => {
    if (!referralKey) {
      reject(new Error('PabblId not set'));
      return;
    }
    // params surevyId, referralKey
    api
      .get(`/survey-link`, {
        params: { surveyId, referralKey, ...restQuery }
      })
      .then(res => {
        if (res && res.data && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.data && res.data.message
                  ? `[${res.data.message}]`
                  : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

/**
 * This function is called by the ExitSurveyCallback. And resolves with
 * the url for the Pabbl callback
 * @param {string} panelistId
 * @param {string} eventKey
 * @param {string} dvj_session
 */
function exitSurvey(panelistId, eventKey, dvj_session) {
  return new Promise((resolve, reject) => {
    api
      .post('exit-survey', { panelistId, eventKey, dvj_session })
      .then(res => {
        if (res && res.data && res.data.status && res.data.data) {
          // data contains the Pabbl callback url
          resolve(res.data.data);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.data && res.data.message
                  ? `[${res.data.message}]`
                  : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

/**
 *
 * @param {Number} timeOnPage - [ms]
 * @param {String} sessionId - (optional) MongoDB id for RespondentSession collection
 * @param {Object} data
 * @param {String} data.referralKey
 * @param {String} data.surveyId
 * @param {Number} data.testMode
 */
function registerPageLeave(timeOnPage, sessionId, data) {
  const { referralKey, surveyId, testMode } = data;
  return new Promise((resolve, reject) => {
    api
      .post('page-leave', {
        timeOnPage,
        sessionId,
        referralKey,
        surveyId,
        testMode
      })
      .then(res => {
        if (res && res.data && res.data.status && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(
            new Error(
              'Unexpected result from api.' +
                (res && res.data && res.data.message
                  ? `[${res.data.message}]`
                  : '')
            )
          );
        }
      })
      .catch(err => reject(err));
  });
}

export { exitSurvey, enterSurvey, registerPageLeave };
