import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

import { CriterionInput } from './Criterion';
import {
  quotumPropTypes,
  propTypes as statePropTypes
} from './SurveyStateManager';

const propTypes = {
  values: quotumPropTypes,
  availableCriteria: statePropTypes.availableCriteria,
  addCriterion: PropTypes.func.isRequired,
  changeId: PropTypes.func.isRequired,
  changeCriterionValue: PropTypes.func.isRequired,
  changeQuota: PropTypes.func.isRequired,
  deleteQuota: PropTypes.func.isRequired
};
const defaultProps = {
  availableCriteria: []
};

const MainContainer = styled.div`
  /* border: 1px solid orange; */
  padding: 8px;
  margin-bottom: 16px;
  position: relative;
`;
const CriteriaContainer = styled.div``;
const CriteriaContainerLabel = styled.h4`
  margin-bottom: 1em;
`;

const CriteriaListContainer = styled.div`
  padding-left: 32px;
  padding-right: 32px;
`;

const CriterionQuestionLabel = styled.div`
  margin-bottom: 4px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledFormControl = styled(({ hide, ...props }) => (
  <FormControl {...props} />
))`
  min-width: 400px;
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};
  margin-left: -48px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledDeleteButton = styled(IconButton)`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const Quota = ({
  values,
  availableCriteria = [],
  addCriterion,
  changeId,
  changeCriterionValue,
  changeQuota,
  deleteQuota
}) => {
  const { id, criteria, quota, reactKey } = values;
  const handleAddCriterion = event => {
    const { value } = event.target;
    addCriterion(reactKey, value);
  };
  const handleChangeQuota = event => {
    const { value } = event.target;
    changeQuota(reactKey, value);
  };
  const handleChangeId = event => {
    const { value } = event.target;
    changeId(reactKey, value);
  };
  const getHandleChangeCriteriaValue = criterionKey => value => {
    changeCriterionValue(reactKey, criterionKey, value);
  };
  const handleDeleteQuota = () => {
    deleteQuota(reactKey);
  };

  const [selectCriterionOpen, setSelectCriterionOpen] = useState(false);

  return (
    <MainContainer>
      <StyledTextField
        label="Id"
        // id={id}
        value={id}
        onChange={handleChangeId}
        helperText="Example: 'Gender quota'"
        margin="dense"
      />
      <CriteriaContainer>
        <CriteriaContainerLabel>Criteria</CriteriaContainerLabel>
        <CriteriaListContainer>
          <div>
            {Object.values(criteria).map(criterion => {
              const { key, question, type, answers } = criterion.criterion;
              const { value } = criterion;
              return (
                <div key={key}>
                  <CriterionQuestionLabel>{question}</CriterionQuestionLabel>
                  <CriterionInput
                    id={`${key}`}
                    type={type}
                    answers={answers}
                    value={value}
                    setValue={getHandleChangeCriteriaValue(key)}
                  />
                  <StyledDivider />
                </div>
              );
            })}
          </div>

          <IconButton
            aria-label="add"
            color="primary"
            onClick={() => setSelectCriterionOpen(true)}
          >
            <AddIcon />
          </IconButton>
          <StyledFormControl hide={!selectCriterionOpen}>
            <Select
              labelId="add-criterion-label"
              id="add-criterion"
              value={''}
              onChange={handleAddCriterion}
              open={selectCriterionOpen}
              onClose={() => setSelectCriterionOpen(false)}
              onOpen={() => setSelectCriterionOpen(true)}
            >
              {availableCriteria.map(criterion => (
                <MenuItem key={criterion.key} value={criterion.key}>
                  {criterion.question}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </CriteriaListContainer>
      </CriteriaContainer>

      <StyledTextField
        label="Quota"
        type="number"
        inputProps={{ min: 0 }}
        value={quota}
        onChange={handleChangeQuota}
        margin="dense"
      />

      <StyledDeleteButton
        aria-label="delete"
        color="primary"
        onClick={handleDeleteQuota}
      >
        <DeleteIcon />
      </StyledDeleteButton>
    </MainContainer>
  );
};
Quota.propTypes = propTypes;
Quota.defaultProps = defaultProps;
export default Quota;
