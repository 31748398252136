import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '../../../auth/react-auth0-spa';
import { surveyProps } from './types';
import styled from 'styled-components';
import {
  Paper,
  TextField,
  InputAdornment,
  Button,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
// import PreviewJson from './PreviewJson';
import QuotasMonitor from './QuotasMonitor';
import SurveyPabblData from './SurveyPabblData';
import { TargetList } from './Targets';
import UrlParameters from './UrlParameters';
import { editDbSurvey } from '../../../models/pabbl/survey';

const propTypes = {
  values: PropTypes.shape(surveyProps).isRequired,
  decipherQuotas: PropTypes.any,
  refreshPabblData: PropTypes.func
};
const defaultProps = {
  refreshPabblData: null
};

const MainContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-bottom: 8px;
`;
const StyledButton = styled(Button)`
  margin: 8px;
`;

const MainDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledContainer = styled(Paper)`
  width: 400px;
  padding: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const StyledContainerL = styled(StyledContainer)`
  width: 816px;
  max-width: 100%;
`;

const ContentSpacer = styled.div`
  /* padding: 16px; */
`;

const StyledTextField = styled(TextField)`
  margin: 4px 0px;
`;

const ExtendedFieldContainer = styled(props => (
  <Paper variant="outlined" {...props} />
))`
  margin-top: 16px;
`;

const UrlParameterContainer = styled.div`
  margin-top: 16px;
`;
const UrlParameterlabel = styled.div`
  margin-bottom: 8px;
`;

// const ClickableText = styled.div`
//   text-decoration: underline;
//   cursor: pointer;
// `;

const ViewSurvey = ({ values, editValues, decipherQuotas, editPabblData }) => {
  // const [showJson, setShowJson] = useState(false);
  const [busy, setBusy] = useState(false);
  const { pabblData, databaseData } = values;
  const { getTokenSilently } = useAuth0();
  const [dbSurveyEdit, setDbSurveyEdit] = useState(false);
  const [dbSurveyEditBusy, setDbSurveyEditBusy] = useState(false);
  const [dbSurveyEditError, setDbSurveyEditError] = useState(false);

  function changeStatus(status) {
    setBusy(true);
    editPabblData(pabblData.id, { status }).finally(() => setBusy(false));
  }

  function saveQuotas(quotas) {
    // setBusy(true);
    return editPabblData(pabblData.id, { quotas });
  }

  function startEditDbSurvey() {
    setDbSurveyEdit({ urlParameters: [], ...databaseData });
  }

  function saveEditDbSurvey() {
    setDbSurveyEditBusy(true);
    setDbSurveyEditError(false);
    getTokenSilently()
      .then(token => {
        editDbSurvey(
          databaseData.surveyId,
          { urlParameters: dbSurveyEdit.urlParameters },
          token
        )
          .then(() => {
            setDbSurveyEditError(false);
            editValues('urlParameters', dbSurveyEdit.urlParameters);
            setDbSurveyEdit(false);
          })
          .catch(e => {
            setDbSurveyEditError('Save error');
            console.error(e);
          })
          .finally(() => {
            setDbSurveyEditBusy(false);
          });
      })
      .catch(e => {
        setDbSurveyEditError('Authentication error');
        console.error(e);
        setDbSurveyEditBusy(false);
      });
  }

  function cancelEditDbSurvey() {
    setDbSurveyEdit(false);
  }

  function changeFuncEditDbSurvey(param) {
    return newValue => {
      setDbSurveyEdit(prevState => ({ ...prevState, [param]: newValue }));
    };
  }

  let statusText = '?';
  const status = pabblData ? pabblData.status : 'unknown';
  if (pabblData) {
    switch (status) {
      case 'standby':
      case 'suspended':
        statusText = `On hold (${status})`;
        break;
      case 'terminated':
        statusText = `Closed (${status})`;
        break;

      default:
        statusText = status;
        break;
    }
  }

  let testMode = false;
  try {
    const params = new URLSearchParams(window.location.search.slice(1));
    testMode = !!params.get('test');
  } catch (e) {
    // no worries
  }

  return (
    <MainContainer>
      <ButtonContainer>
        <div>Current status: {statusText}</div>
        <StyledButton
          disabled={busy || status === 'active'}
          key="publish"
          onClick={() => changeStatus('active')}
          variant="contained"
        >
          Publish
        </StyledButton>
        <StyledButton
          disabled={busy || status === 'standby' || status === 'suspended'}
          key="hold"
          onClick={() => changeStatus('suspended')}
          variant="contained"
        >
          On hold
        </StyledButton>
        <StyledButton
          disabled={busy || status === 'terminated'}
          key="end"
          onClick={() => changeStatus('terminated')}
          variant="contained"
        >
          Close
        </StyledButton>
      </ButtonContainer>

      {pabblData && pabblData.quotas && pabblData.quotas.length ? (
        <StyledContainerL>
          <h3>Quotas</h3>
          <QuotasMonitor
            initialQuotas={databaseData ? databaseData.quotas : []}
            currentQuotas={pabblData.quotas}
            decipherQuotas={decipherQuotas}
            saveQuotas={saveQuotas}
          />
        </StyledContainerL>
      ) : null}
      {testMode && (
        <StyledContainerL>
          <h3>Test links</h3>
          <p>Work in progress for testing the links.</p>
          <p>
            By pressing 'Test Pabbl user link' you will follow the same link as
            a Pabbl user clicking the survey in the Pabbl app. This is the route
            for a user that has already done a survey before and is therefore
            already registered in Kinesis panel.
          </p>
          <p>
            By pressing 'test live link' you will not be redirected to Pabbl,
            but will be redirected to the survey with parameters as if you are a
            new Pabbl user.
            {/* By clicking on the button below you will follow the same link as a
            Pabbl user clicking the survey in the Pabbl app. There are some
            caveats though. First of all there is a difference in the way a user
            is redirected dependent on whether the user has done a survey of
            ours before. This link will follow the path for a pabbl user that
            has participated in a DVJ survey before ( and therfore is already
            known in Kinesis panel ) */}
          </p>
          {pabblData && (
            <>
              <Button
                variant="contained"
                color="primary"
                href={pabblData.redirectLink}
                disabled={!pabblData.redirectLink}
              >
                Test Pabbl user link
              </Button>
              <Button
                variant="contained"
                color="primary"
                href={`${pabblData.liveLink}&testMode=2`}
                style={{ marginLeft: 16 }}
              >
                Test live link
              </Button>
            </>
          )}
        </StyledContainerL>
      )}

      <MainDataContainer>
        <StyledContainer>
          <ContentSpacer>
            <h3>Survey data</h3>
            <StyledTextField
              id="surveyId"
              label="Survey id (Pabbl)"
              value={values.surveyId}
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
            <StyledTextField
              id="decipherId"
              label="Survey id (Decipher)"
              value={values.decipherId}
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
            <StyledTextField
              id="projectId"
              label="Project id (Kinesis)"
              value={values.projectId}
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
            <StyledTextField
              id="title"
              label="Title"
              value={values.title}
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
            {/* <StyledTextField
              id="status"
              label="Status"
              value={values.status}
              fullWidth
              InputProps={{
                readOnly: true
              }}
            /> */}
            <StyledTextField
              id="length"
              label="Survey length"
              value={values.length}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">min</InputAdornment>
                )
              }}
              fullWidth
            />
            <StyledTextField
              id="quota"
              label="Quota (overall)"
              value={
                databaseData && databaseData.quota !== null
                  ? databaseData.quota
                  : ''
              }
              InputProps={{
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
            <StyledTextField
              id="startDate"
              label="Date from"
              // type="date"
              InputProps={{
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              value={values.startDate}
              fullWidth
            />
            <StyledTextField
              id="endDate"
              label="Date to"
              // type="date"
              InputProps={{
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              value={values.endDate}
              fullWidth
            />
            {dbSurveyEdit ? (
              <UrlParameterContainer>
                <UrlParameterlabel className="MuiFormLabel-root">
                  Edit url parameters
                </UrlParameterlabel>
                <UrlParameters
                  parameters={dbSurveyEdit.urlParameters}
                  setParameters={changeFuncEditDbSurvey('urlParameters')}
                  explanation="Make sure that in your project in Kinesis panel the campaign named '[auto] existing Pabbl users' has the same url parameters!!"
                  errorText={dbSurveyEditError}
                />
                <ButtonContainer>
                  <StyledButton
                    onClick={saveEditDbSurvey}
                    variant="contained"
                    disabled={dbSurveyEditBusy}
                  >
                    Save
                  </StyledButton>
                  <StyledButton
                    onClick={cancelEditDbSurvey}
                    variant="contained"
                    disabled={dbSurveyEditBusy}
                  >
                    Cancel
                  </StyledButton>
                </ButtonContainer>
              </UrlParameterContainer>
            ) : (
              <StyledTextField
                id="urlParameters"
                label="Url parameters"
                value={
                  values.urlParameters
                    ? values.urlParameters
                        .map(
                          param =>
                            `${encodeURI(param.key)}${
                              typeof param.value === 'undefined' ||
                              param.value === '' ||
                              param.value === null
                                ? ''
                                : `=${encodeURI(param.value)}`
                            }`
                        )
                        .join('&')
                    : ''
                }
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="edit url parameters"
                        onClick={startEditDbSurvey}
                      >
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
            {/* <StyledTextField
              id="liveLink"
              label="url"
              value={values.liveLink}
              fullWidth
              InputProps={{
                readOnly: true
              }}
            /> */}
            {/* <ExtendedFieldContainer>
              <TargetList targets={values.targets} />
            </ExtendedFieldContainer> */}
            {/* <ExtendedFieldContainer>
              {showJson ? (
                <>
                  <ClickableText onClick={() => setShowJson(false)}>
                    hide json
                  </ClickableText>
                  <PreviewJson json={values.json} />
                </>
              ) : (
                <ClickableText onClick={() => setShowJson(true)}>
                  show json
                </ClickableText>
              )}
            </ExtendedFieldContainer> */}
          </ContentSpacer>
        </StyledContainer>

        <StyledContainer>
          <h3>Pabbl data</h3>
          {pabblData && (
            <SurveyPabblData
              pabblData={pabblData}
              editPabblData={editPabblData}
            />
          )}
        </StyledContainer>
      </MainDataContainer>
      {values.targets && values.targets.length > 0 ? (
        <StyledContainerL>
          <h3>Targets</h3>
          <ExtendedFieldContainer>
            <TargetList targets={values.targets} />
          </ExtendedFieldContainer>
        </StyledContainerL>
      ) : null}
    </MainContainer>
  );
};
ViewSurvey.propTypes = propTypes;
ViewSurvey.defaultProps = defaultProps;
export default ViewSurvey;
